import React, { useState } from 'react';
import AppContext from './AppContext';

const AppProvider = ({ children }) => {
    const [token, setToken] = useState({}) 
    const [lastLogInTimestamp, setLastLogInTimestamp] = useState()
    const [isExpanded, setIsExpanded] = useState(true)

    return (
        <AppContext.Provider value={{ 
            token, 
            setToken,
            lastLogInTimestamp, 
            setLastLogInTimestamp,
            isExpanded,
            setIsExpanded
         }}>
            {children} 
        </AppContext.Provider>
    );
};

export default AppProvider;