// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesAllUsers.css'
import { SIDER_TABS } from '../../../utils/Constants';

// component imports
import Sider from '../../CommonComponents/Sider/Sider';
import Header from '../../CommonComponents/Header/Header';
import UserTable from './UsersTable/UserTable';
import DeleteUserPopup from '../UserManager/UserActions/ActionPopups/DeleteUserPopup/DeleteUserPopup';
import Layout from '../../CommonComponents/Layout/Layout';

// asset imports


const AllUsers = () => {
  // non-state variables
  const navigate = useNavigate();

  // state variables

  // use effects'

  // api functions

  // helper functions
  return (
    <Layout selectedTab={SIDER_TABS?.USERS}>
      <UserTable/>
    </Layout>
  );
};

export default AllUsers;

