// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesAffiliates.css'
import { AFFILIATE_HEADER_TABS, SIDER_TABS } from '../../utils/Constants';
import AffiliatePanelInstance from '../../api/AffiliatePanelInstance';

// component imports
import Sider from '../CommonComponents/Sider/Sider';
import Header from '../CommonComponents/Header/Header';
import RebetLoader from '../CommonComponents/RebetLoader/RebetLoader';
import AffiliatesHeader from './AffiliatesHeader/AffiliatesHeader';
import AffiliateKPIs from './AffiliateKPIs/AffiliateKPIs';
import Financials from './Financials/Financials';
import IndividualStats from './IndividualStats/IndividualStats';
import { API_ROUTES } from '../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../api/PrivateAdminPanelInstance';
import Layout from '../CommonComponents/Layout/Layout';

// asset imports


const Affiliates = () => {
  // non-state variables
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [selectedTab, setSelectedTab] = useState(AFFILIATE_HEADER_TABS?.AFFILIATE_KPIS)
  const [referralCodes, setReferralCodes] = useState([])

  // use effects

  // api functions
  const getAllReferralCodes = async () => {

    setIsApiRunning(true)

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_ALL_REFERRAL_CODES)

      const referralCodes = response.data.data
      setReferralCodes(referralCodes.referral_code_data)

      setIsApiRunning(false)
    } catch (error) {
      setIsApiRunning(false)
      console.log("error getting referral codes", error)
    }

  }

  // helper functions

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }

  useEffect(() => {
    getAllReferralCodes()
  }, [])


  return (
    <Layout selectedTab={SIDER_TABS?.AFFILIATE}>
      <div className='affiliate-body-container'>
        <AffiliatesHeader selectedTab={selectedTab} setSelectedTab={handleSetSelectedTab} />

        {
          isApiRunning
            ? (
              <RebetLoader
                height={'100px'}
                width={'100px'} />
            ) :
            selectedTab === AFFILIATE_HEADER_TABS?.AFFILIATE_KPIS
              ? (
                <AffiliateKPIs referralCodes={referralCodes} />
              ) : selectedTab === AFFILIATE_HEADER_TABS?.FINANCIALS
                ? (
                  <Financials referralCodes={referralCodes} />
                ) : selectedTab === AFFILIATE_HEADER_TABS?.INDIVIDUAL_STATS
                  ? (
                    <IndividualStats referralCodes={referralCodes} />
                  ) :
                  (
                    null
                  )
        }

      </div>
    </Layout>
  );
};

export default Affiliates;