export const AppColors = {
    black: "#000000",
    white: "#FFFFFF",
    primary: "#FD6F0F",
    darkGrey: "#252729",
    lightGrey: '#777777',
    panelBackground: '#1A1C1E',
    panelDividerLine: '#2F2F2F',
    panelHeaderTextGrey: '#BBBBB2',
    undepositedColor: '#E7D2C2'
}