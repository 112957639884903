import React, { useContext, useState } from 'react';
import Sider from '../Sider/Sider';
import Header from '../Header/Header';
import AppContext from '../../../context/AppContext';

// import { Container } from './styles';

function Layout({ selectedTab, children }) {
  const {isExpanded, setIsExpanded} = useContext(AppContext)

  return (
    <div className="pageContainer">
      <Header isExpanded={isExpanded} setIsExpanded={setIsExpanded}/>
      <div style={{display: 'flex'}}>
        <Sider isExpanded={isExpanded} selectedTab={selectedTab} />
        <div className={`contentContainer ${isExpanded ? 'expanded' : 'collapsed'}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;