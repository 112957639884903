// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesEditAdmin.css'
import { 
  EVERYONE_ROUTES,
  ADMIN_ROUTES,
  USER_ROUTES,
  KPI_ROUTES 
} from '../../../utils/Constants';
import { API_ROUTES, SLASHLESS_API_ROUTES } from '../../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../utils/ErrorUtils';
import { ADMIN_TYPES } from '../../../utils/Constants';

// component imports
import CheckBox from '../../CommonComponents/CheckBox/CheckBox';
import RebetLoader from '../../CommonComponents/RebetLoader/RebetLoader';
import DropDown from '../../CommonComponents/DropDown/DropDown';

// asset imports


const EditAdmin = () => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)

  // state variables
  const [allAdmins, setAllAdmins] = useState([])
  const [dropDownOptions, setDropDownOptions] = useState("")
  const [selectedAdminName, setSelectedAdminName] = useState("")
  const [selectedAdmin, setSelectedAdmin] = useState({})
  const [customerSupport, setCustomerSupport] = useState(false)
  const [superAdmin, setSuperAdmin] = useState(false)
  const [isApiSending, setIsApiSending] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  const [lastApiCallSuccess, setLastApiCallSuccess] = useState(false)
  const [lastApiCallSuccessMessage, setLastApiCallSuccessMessage] = useState("")
  const [submitButtonActive, setSubmitButtonActive] = useState(false)
  
  // use effects
  useEffect(() => {
    getAllAdmins()
  }, [])

  useEffect(() => {
    const selectedAdmin = allAdmins?.find(admin => admin?.admin_name === selectedAdminName)
    setSelectedAdmin(selectedAdmin)
  }, [selectedAdminName])

  useEffect(() => {

    if (selectedAdmin?.group_name === ADMIN_TYPES?.SUPER_ADMIN ) {
      setSuperAdmin(true)
      setCustomerSupport(false)
    } else if (selectedAdmin?.group_name === ADMIN_TYPES?.CUSTOMER_SUPPORT) {
      setCustomerSupport(true)
      setSuperAdmin(false)
    }

  }, [selectedAdmin])

  useEffect(() => {

    if (selectedAdmin && (customerSupport || superAdmin)) {
      setSubmitButtonActive(true)
    } else {
      setSubmitButtonActive(false)
    }

  }, [selectedAdmin, customerSupport, superAdmin])

  // api functions
  const updateAdminPermissions = async () => {

    setIsApiSending(true)
    setLastApiCallError(false)
    setLastApiErrorMessage("")
    setLastApiCallSuccess(false)
    setLastApiCallSuccessMessage("")
    
    const params = {
      admin_id: selectedAdmin?.admin_id,
      group_name: 
        customerSupport 
        ? ADMIN_TYPES?.CUSTOMER_SUPPORT
        : ADMIN_TYPES?.SUPER_ADMIN
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.EDIT_ADMIN, params)

      setLastApiCallSuccess(true)
      setLastApiCallSuccessMessage("Admin edited successfully!")
      setIsApiSending(false)

      getAllAdmins()

    } catch (error) {
      console.log("error creating admin", error?.resposne)
      setIsApiSending(false)
      setLastApiCallError(true)
      setLastApiErrorMessage(error?.response?.data?.message)
      handleExpiredTokenError(navigate, error?.response)
    }
  
  }

  const getAllAdmins = async () => {
    
    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_ADMINS)

      setAllAdmins(response?.data?.data)

      const temp_admin_dict = response?.data?.data.reduce((dict, admin) => {
        dict[admin?.admin_id] = admin?.admin_name;
        return dict;
      }, {});

      setDropDownOptions(temp_admin_dict)

    } catch (error) {
      console.log("error fetching admins", error?.reponse)
    }

  }

  // helper functions

  const handleCustomerSupportPressed = () => {
    setSuperAdmin(false)
    setCustomerSupport(!customerSupport)
  }

  const handleSuperAdminPressed = () => {
    setCustomerSupport(false)
    setSuperAdmin(!superAdmin)
  }

  const handleSetSelectedAdmin = (value) => {
    setSelectedAdminName(value)
  }

  const handleEditAdminPressed = () => {
    if (submitButtonActive) {
      updateAdminPermissions()
    }
  }

  return (
    <div className="edit-admin">

      <div className='edit-admin-header-text'>
        Edit Admin
      </div>

      <div className='edit-admin-drop-down-container'>
        <DropDown 
              drop_down_options={dropDownOptions}
              selected_value={selectedAdminName}
              set_selected_value={handleSetSelectedAdmin}
              />
      </div>
      

      <div className='admin-permissions-row'>
        <CheckBox
            onClicked={handleCustomerSupportPressed}
            isSelected={customerSupport}
            width={'30px'}
            height={'30px'}
            border_radius={'8px'}
          />
          <div className='admin-permissions-label'>
            Customer Support
          </div>
      </div>

      <div className='admin-permissions-row'>
        <CheckBox
            onClicked={handleSuperAdminPressed}
            isSelected={superAdmin}
            width={'30px'}
            height={'30px'}
            border_radius={'8px'}
          />
          <div className='admin-permissions-label'>
            Super Admin
          </div>
      </div>


      <div className='create-admin-button-container'>
          <button 
            className={
              submitButtonActive ? 'submit-button' : 'submit-button-inactive'
            }
            onClick={handleEditAdminPressed}
          >
            {
              isApiSending
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : "Update Permissions"
            }
          </button>

          {
            lastApiCallError 
              ? (
                <div className='error-message'>
                  {lastApiErrorMessage}
                </div>
              ) 
              : null
            }

            {
              lastApiCallSuccess 
              ? (
                <div className='success-message'>
                  {lastApiCallSuccessMessage}
                </div>
              ) 
              : null
          }


      </div>
      
    </div>
  );
};

export default EditAdmin;