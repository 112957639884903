import React, { useEffect, useReducer } from 'react';

import './stylesAffiliateKPIs.css'
import BarGraph from './BarGraph/BarGraph';
import FTDListingTable from './FTDListingTable/FTDListingTable';
import TopPerformers from './TopPerfomers/TopPerformers';
import LineGraph from './LineGraph/LineGraph';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../api/ApiRoutes';
import axios from 'axios';
import { initialState, affiliateKpisReducer, SET_IS_API_RUNNING, SET_KPI, SET_TOP_PERFORMERS, SET_AVG_MONTHLY_LTV } from '../../../reducers/affiliateKpisReducer';
import RebetLoader from '../../CommonComponents/RebetLoader/RebetLoader';
import { formatNumber } from 'chart.js/helpers';

function AffiliateKPIs({ referralCodes }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const [state, dispatch] = useReducer(affiliateKpisReducer, initialState);

  const getAffiliateKpisStats = async () => {
    dispatch({ type: SET_IS_API_RUNNING, payload: true });

    try {
      const requests = [
        PrivateAdminPanelInstance?.post(API_ROUTES?.TOTAL_SIGNUPS_YEAR),
        PrivateAdminPanelInstance?.post(API_ROUTES?.TOTAL_MONTHLY_SIGNUPS),
        PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_FTDS),
        PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_MONTHLY_FTDS),
        PrivateAdminPanelInstance?.post(API_ROUTES?.TOP_PERFORMERS_CURRENT_MONTH),
        PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_MONTHLY_DEPOSIT_RATIO),
      ];

      const responses = await Promise.allSettled(requests);

      const [totalSignupsYear, totalMonthlySignups, totalFtds,
        monthlyFtds, topPerfomersResponse, monthlyDepositRatio] = responses;
      const totalDepositRatio = (totalFtds.value.data.data.total_ftds / totalSignupsYear.value.data.data.totalsignups) * 100;

      if (topPerfomersResponse.status === 'fulfilled') {
        const topPerfomersData = topPerfomersResponse.value.data.data['top_performers'];
        dispatch({
          type: SET_TOP_PERFORMERS,
          payload: topPerfomersData,
        });
      }

      if (totalSignupsYear.status === 'fulfilled') {
        dispatch({
          type: SET_KPI,
          payload: { index: 0, data: { number: formatNumber(totalSignupsYear.value.data.data.totalsignups, 'en'), percentage: '', up: '' } },
        });
      }

      if (totalMonthlySignups.status === 'fulfilled') {
        dispatch({
          type: SET_KPI,
          payload: { index: 1, data: { number: formatNumber(totalMonthlySignups.value.data.data.total_monthly_signups, 'en'), percentage: calculatePercentageChange(totalMonthlySignups.value.data.data.percentage_change), up: checkIfUp(totalMonthlySignups.value.data.data.percentage_change) } },
        });
      }

      if (totalFtds.status === 'fulfilled') {
        dispatch({
          type: SET_KPI,
          payload: { index: 2, data: { number: formatNumber(totalFtds.value.data.data.total_ftds, 'en'), percentage: '', up: '' } },
        });
      }

      if (monthlyFtds.status === 'fulfilled') {
        dispatch({
          type: SET_KPI,
          payload: { index: 3, data: { number: formatNumber(monthlyFtds.value.data.data.total_monthly_ftds, 'en'), percentage: calculatePercentageChange(monthlyFtds.value.data.data.percentage_change), up: checkIfUp(monthlyFtds.value.data.data.percentage_change) } },
        });
      }

      dispatch({
        type: SET_KPI,
        payload: { index: 4, data: { number: `${formatNumber(totalDepositRatio, 'en')}%`, percentage: '', up: '' } },
      });

      if (monthlyDepositRatio.status === 'fulfilled') {
        dispatch({
          type: SET_KPI,
          payload: { index: 5, data: { number: `${monthlyDepositRatio.value.data.data.current_month_percentage}%`, percentage: calculatePercentageChange(monthlyDepositRatio.value.data.data.percentage_change), up: checkIfUp(monthlyDepositRatio.value.data.data.percentage_change) } },
        });
      }

      dispatch({ type: SET_IS_API_RUNNING, payload: false });

    } catch (error) {
      dispatch({ type: SET_IS_API_RUNNING, payload: false });
      console.log("error getting affiliate Kpis", error);
    }

  };

  const calculatePercentageChange = (percentage) => {
    if (typeof percentage === 'string' || isNaN(percentage)) {
      percentage = parseFloat(percentage);
    }

    if (percentage === 0) return '0%';

    const sign = percentage > 0 ? '+' : '';
    return `${sign}${percentage.toFixed(2)}%`;
  };

  const checkIfUp = (percentage) => {
    return percentage >= 0
  }

  useEffect(() => {
    getAffiliateKpisStats();
  }, []);

  return (
    <div className='affiliate-kpis-container'>
      <div className='affiliate-kpis-body'>
        <div className={`affiliate-kpi-top ${state.isApiRunning ? 'loading' : ''}`}>
          {state.affiliateKpis.map((kpi, index) => (
            <div key={index} className={`affiliate-kpi ${state.isApiRunning ? 'tilt-card' : ''}`}>
              <div className='affiliate-kpi-title'>{kpi.name}</div>
              {state.isApiRunning ?
                <RebetLoader
                  width={'50px'}
                  height={'50px'} />
                :
                <>
                  <div className='affiliate-kpi-value'>{kpi.number}</div>
                  <p className={`${kpi.up ? 'increase' : 'decrease'}`}>{kpi.percentage}</p>
                </>
              }
            </div>
          ))}
        </div>

        <div className='affiliate-kpis-graphs'>
          <BarGraph title='Daily FTDs' referralCodes={referralCodes} />
          <FTDListingTable referralCodes={referralCodes} />
          <div className='affiliates-bottom-graphs'>
            <TopPerformers topPerformers={state.topPerformers} isApiRunning={state.isApiRunning} />
            <LineGraph referralCodes={referralCodes} title='Avg Life Time Value (LTV)' />
          </div>
        </div>
      </div>

    </div>
  );
}

export default AffiliateKPIs;