import React, { useState, useRef, useEffect } from 'react';
import './stylesDropdownMultipleCheckbox.css';

const DropdownMultipleCheckbox = ({ options, selectedOptions, onChange }) => {
  const [selected, setSelected] = useState(selectedOptions);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dropdownRef = useRef(null);

  const handleCheckboxChange = (option) => {
    let newSelected;

    if (option === "selectAll") {
      if (selected.includes("selectAll")) {
        newSelected = [];
      } else {
        newSelected = ["selectAll", ...options];
      }
    } else {
      if (selected.includes("selectAll")) {
        newSelected = selected.filter(item => item !== "selectAll").filter(item => item !== option)
      } else {
        if (selected.includes(option)) {
          newSelected = selected.filter(item => item !== option);
        } else {
          newSelected = [...selected, option];
        }
      }
    }

    setSelected(newSelected);
  };

  const handleUnselectAll = () => {
    setSelected([]);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const filteredOptions = options
    .filter(option =>
      option.toLowerCase().includes(searchValue.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = selected.includes(a);
      const bSelected = selected.includes(b);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });

  const onHandleSearch = () => {
    onChange(selected.includes("selectAll"), selected);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (options.includes(searchValue)) {
      handleCheckboxChange(searchValue);
    }
  }, [searchValue]);

  return (
    <div className="dropdown-multiple-checkbox" ref={dropdownRef}>
      <button className="dropdown-button" onClick={toggleDropdown}>
        Referral Code {isOpen ? "▲" : "▼"}
      </button>
      {isOpen && (
        <div className="dropdown-content">
          <div className='dropdown-content-header'>
            <input
              type="text"
              placeholder="Search Referral Code..."
              className="searchInput"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <span onClick={handleUnselectAll}>Unselect All</span>
            <button onClick={onHandleSearch}>Search</button>
          </div>
          <div className='options-container'>
            <label className="dropdown-item">
              <input
                type="checkbox"
                checked={selected.includes("selectAll")}
                onChange={() => handleCheckboxChange("selectAll")}
              />
              Select All
            </label>
            {filteredOptions.map((option, index) => (
              <label key={index} className="dropdown-item">
                <input
                  type="checkbox"
                  checked={selected.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMultipleCheckbox;
