// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesTransactionsByState.css'
import DropDown from '../../CommonComponents/DropDown/DropDown';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../api/ApiRoutes';
import RebetLoader from '../../CommonComponents/RebetLoader/RebetLoader';
import Transactions from './Transactions/Transactions';
import CashInOut from './CashInCashOut/CashInOut';
import normalizeAndMergeStates from '../../../utils/NormalizeAndMergeStates';

// component imports

// asset imports

const TransactionsByState = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  // state variables
  const [apiIsRunning, setApiIsRunning] = useState(true);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [transactionsByState, setTransactionsByState] = useState({});

  // use effects'

  useEffect(() => {
    const refreshAndGetTransactionsByState = async () => {
      setApiIsRunning(true)
      try {
        const refresh = await PrivateAdminPanelInstance?.post(API_ROUTES?.REFRESH_TRANSACTIONS_BY_STATE);

        if (refresh?.status === 200) {
          const transactionsByState = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TRANSACTIONS_BY_STATE);

          if (transactionsByState?.status === 200) {
            const response = transactionsByState.data.data;
            const mergedData = normalizeAndMergeStates(response);

            const states = Object.keys(mergedData);
            const selectedState = { state: states[0], data: mergedData[states[0]] };

            setStates(states);
            setSelectedState(selectedState);
            setTransactionsByState(mergedData);
            setApiIsRunning(false)
          } else {
            throw new Error('Get transactions by state failed');
          }
        } else {
          throw new Error('Refresh failed');
        }
      } catch (error) {
        if (error?.response.data?.message !== 'refresh already in progress') {
          setApiIsRunning(false)
          console.error("error fetching transactions by state data", error?.response || error)
        }
      }
    }
    refreshAndGetTransactionsByState()
  }, [])

  // api functions

  // helper functions
  const handleSelectedState = (state) => {
    const selectedState = { state, data: transactionsByState[state] };
    setSelectedState(selectedState)
  }


  return (
    <div className="transactions-by-state-container">
      {apiIsRunning && !selectedState ? (
        <RebetLoader height={'150px'} width={'150px'} />
      ) : (
        <>
          <div className='transactions-by-state-header'>
            <div className='selected-state-label'>{selectedState.state} State</div>
            <DropDown
              drop_down_options={states}
              selected_value={selectedState.state}
              set_selected_value={handleSelectedState}
            />
          </div>
          <>
            <CashInOut selectedState={selectedState?.state} cashIn={selectedState?.data?.cash_in} cashOut={selectedState?.data?.cash_out} />
            <Transactions title='Transactions By Day' color='#26BF94' graphData={selectedState?.data?.by_day} formatterType='day'/>
            <Transactions title='Transactions By Month' color='#6E2EF5' graphData={selectedState?.data?.by_month} formatterType='month'/>
          </>
        </>
      )}

    </div>
  );
};

export default TransactionsByState;