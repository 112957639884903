// third party imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesDropDown.css';

// component imports

// asset imports

const DropDown = ({
  drop_down_options,
  selected_value,
  set_selected_value
}) => {
  // non-state variables
  const navigate = useNavigate();

  const DROP_DOWN_STATES = {
    COLLAPSED: "collapsed",
    EXPANDED: "expanded"
  };

  // state variables
  const [dropDownState, setDropDownState] = useState(DROP_DOWN_STATES.COLLAPSED);
  const [searchValue, setSearchValue] = useState("");

  // use effects'

  // api functions

  // helper functions
  const handleToggleDropDown = () => {
    setDropDownState(
      dropDownState === DROP_DOWN_STATES.COLLAPSED ? DROP_DOWN_STATES.EXPANDED : DROP_DOWN_STATES.COLLAPSED
    );
  };

  const handleOptionClick = (key, value) => {
    set_selected_value(value);
    setDropDownState(DROP_DOWN_STATES.COLLAPSED);
  };

  const filteredOptions = Object.entries(drop_down_options).filter(([key, value]) =>
    value.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="drop-down-container">
      <button
        className="drop-down"
        onClick={handleToggleDropDown}
      >
        <p className={selected_value !== "" ? "selected-item-text" : "unselected-item-text"}>
          {
            selected_value
              ? selected_value + (dropDownState === DROP_DOWN_STATES.COLLAPSED ? " ▼" : " ▲")
              : (dropDownState === DROP_DOWN_STATES.COLLAPSED ? "Select ▼" : "Select ▲")
          }
        </p>
      </button>
      {dropDownState === DROP_DOWN_STATES.EXPANDED && (
        <div className={Object.keys(drop_down_options).length < 10 ? "drop-down-list" : "drop-down-list-bigger"}>
          {Object.keys(drop_down_options).length >= 10 && (
            <input
              type="text"
              placeholder="Search Referral Code..."
              className="searchInput"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}
          <div className='drop-down-list-bigger-body'>
            {filteredOptions.map(([key, value]) => (
              <div
                key={key}
                className="drop-down-list-item"
                onClick={() => handleOptionClick(key, value)}
              >
                {value}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
