// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesAddAdmin.css'
import { API_ROUTES } from '../../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../utils/ErrorUtils';
import { ADMIN_TYPES } from '../../../utils/Constants';

// component imports
import CheckBox from '../../CommonComponents/CheckBox/CheckBox';
import RebetLoader from '../../CommonComponents/RebetLoader/RebetLoader';

// asset imports


const AddAdmin = () => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)

  // state variables
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [customerSupport, setCustomerSupport] = useState(false)
  const [superAdmin, setSuperAdmin] = useState(false)
  const [isApiSending, setIsApiSending] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  const [lastApiCallSuccess, setLastApiCallSuccess] = useState(false)
  const [lastApiCallSuccessMessage, setLastApiCallSuccessMessage] = useState("")
  const [submitButtonActive, setSubmitButtonActive] = useState(false)
  
  // use effects
  useEffect(() => {

    if (
      email?.length !== 0
      && password?.length !== 0
      && firstName?.length !== 0
      && lastName?.length !== 0
      && (superAdmin || customerSupport)
    ) {
      setSubmitButtonActive(true)
    } else {
      setSubmitButtonActive(false)
    }

  }, [email, password, firstName, lastName, customerSupport, superAdmin])

  // api functions
  const createAdmin = async () => {

    setIsApiSending(true)
    setLastApiCallError(false)
    setLastApiErrorMessage("")
    setLastApiCallSuccess(false)
    setLastApiCallSuccessMessage("")
    
    const params = {
      username: email?.split('@')[0],
      password: password, 
      email: email, 
      first_name: firstName, 
      last_name: lastName, 
      address: "NH", 
      group_name: 
        customerSupport 
        ? ADMIN_TYPES?.CUSTOMER_SUPPORT
        : ADMIN_TYPES?.SUPER_ADMIN
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.CREATE_ADMIN, params)

      setLastApiCallSuccess(true)
      setLastApiCallSuccessMessage("Admin create successfully!")
      setIsApiSending(false)
      setEmail("")
      setPassword("")
      setFirstName("")
      setLastName("")

    } catch (error) {
      console.log("error creating admin", error)
      console.log("error creating admin", error?.resposne)
      setIsApiSending(false)
      setLastApiCallError(true)
      setLastApiErrorMessage(error?.response?.data?.message)
      handleExpiredTokenError(navigate, error?.response)
    }
  
  }

  // helper functions
  const handleEmailChange = (event) => {
    setEmail(event?.target?.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event?.target?.value)
  }

  const handleFirstNameChange = (event) => {
    setFirstName(event?.target?.value)
  }

  const handleLastNameChange = (event) => {
    setLastName(event?.target?.value)
  }

  const handleCustomSupportPermissions = () => {
    setCustomerSupport(!customerSupport)
    setSuperAdmin(false)
  }

  const handleSuperAdminPermissions = () => {
    setSuperAdmin(!superAdmin)
    setCustomerSupport(false)
  }

  const handleCreateAdminPressed = () => {
    if(submitButtonActive) {
      createAdmin()
    }
  }

  return (
    <div className="add-admin">

      <div className='add-admin-header-text'>
        Create Admin
      </div>

      <input
            type="text"
            placeholder="Email..."
            className="add-admin-field"
            value={email}
            onChange={handleEmailChange}
          />
      <input
            type="text"
            placeholder="Password with 8 characters, symbol, #, and capital..."
            className="add-admin-field"
            value={password}
            onChange={handlePasswordChange}
          />

      <input
            type="text"
            placeholder="First name..."
            className="add-admin-field"
            value={firstName}
            onChange={handleFirstNameChange}
          />

      <input
            type="text"
            placeholder="Last name..."
            className="add-admin-field"
            value={lastName}
            onChange={handleLastNameChange}
          />

      <div className='admin-permissions-row'>
        <CheckBox
            onClicked={handleCustomSupportPermissions}
            isSelected={customerSupport}
            width={'30px'}
            height={'30px'}
            border_radius={'8px'}
          />
          <div className='admin-permissions-label'>
            Customer Support
          </div>
      </div>

      <div className='admin-permissions-row'>
        <CheckBox
            onClicked={handleSuperAdminPermissions}
            isSelected={superAdmin}
            width={'30px'}
            height={'30px'}
            border_radius={'8px'}
          />
          <div className='admin-permissions-label'>
            Super Admin
          </div>
      </div>

      <div className='create-admin-button-container'>
          <button 
            className={
              submitButtonActive ? 'submit-button' : 'submit-button-inactive'
            }
            onClick={handleCreateAdminPressed}
          >
            {
              isApiSending
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : "Create Admin"
            }
          </button>

          {
            lastApiCallError 
              ? (
                <div className='error-message'>
                  {lastApiErrorMessage}
                </div>
              ) 
              : null
            }

            {
              lastApiCallSuccess 
              ? (
                <div className='success-message'>
                  {lastApiCallSuccessMessage}
                </div>
              ) 
              : null
          }


      </div>
      
    </div>
  );
};

export default AddAdmin;