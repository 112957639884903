// third party imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesAffiliatesHeader.css'
import { AFFILIATE_HEADER_TABS } from '../../../utils/Constants';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../api/ApiRoutes';
import RebetLoader from '../../CommonComponents/RebetLoader/RebetLoader';

// component imports

// asset imports


const AffiliatesHeader = ({
  selectedTab,
  setSelectedTab
}) => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false)

  // use effects'

  // api functions
  const refreshAffiliatePanelData = async () => {

    setIsApiRunning(true)

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.REFRESH_ADMIN_AFFILIATE_DATA)

      console.log("rex", response)

      setIsApiRunning(false)

    } catch (error) {
      setIsApiRunning(false)
      console.log("error refreshing affiliate panel", error)
    }

  }

  // helper functions
  const handleRefreshPressed = () => {
    refreshAffiliatePanelData()
  }

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }


  return (
    <div className='affiliate-panel-header'>

      <button
        className={selectedTab === AFFILIATE_HEADER_TABS?.AFFILIATE_KPIS ? 'header-tab-selected' : 'header-tab'}
        onClick={() => handleSetSelectedTab(AFFILIATE_HEADER_TABS?.AFFILIATE_KPIS)}
      >
        <p className={selectedTab === AFFILIATE_HEADER_TABS?.AFFILIATE_KPIS ? 'tab-text-selected' : 'tab-text'}>
          Affiliate KPIs
        </p>
      </button>

      <button
        className={selectedTab === AFFILIATE_HEADER_TABS?.FINANCIALS ? 'header-tab-selected' : 'header-tab'}
        onClick={() => handleSetSelectedTab(AFFILIATE_HEADER_TABS?.FINANCIALS)}
      >
        <p className={selectedTab === AFFILIATE_HEADER_TABS?.FINANCIALS ? 'tab-text-selected' : 'tab-text'}>
          Financials
        </p>
      </button>

      <button
        className={selectedTab === AFFILIATE_HEADER_TABS?.INDIVIDUAL_STATS ? 'header-tab-selected' : 'header-tab'}
        onClick={() => handleSetSelectedTab(AFFILIATE_HEADER_TABS?.INDIVIDUAL_STATS)}
      >
        <p className={selectedTab === AFFILIATE_HEADER_TABS?.INDIVIDUAL_STATS ? 'tab-text-selected' : 'tab-text'}>
          Individual Stats
        </p>
      </button>

      <button
        onClick={handleRefreshPressed}
        className={`refresh-button ${isApiRunning ? 'refresh-button-sending' : ''}`}
        disabled={isApiRunning}
      >
        {isApiRunning ?
          <RebetLoader
            height={'50px'}
            width={'50px'} /> :
          <p>Refresh data</p>}
      </button>

    </div>
  );
};

export default AffiliatesHeader;

