import React, { useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesIndividualStatsTable.css'
import IndividualStatsTableRow from './IndividualStatsTableRow/IndividualStatsTableRow';
import DatePicker from 'react-datepicker';
import { format, isWithinInterval } from 'date-fns';
// import IndividualStatsTableRow from '../IndividualStatsTableRow/IndividualStatsTableRow';

function IndividualStatsTable({ data }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange

  const [individualStatsToShowInTable, setindividualStatsToShowInTable] = useState(data.data)

  return (
    <div className="individual-stats-table">

      <>
        <div className='head-controls'>

          <div className='header-text-container'>
            <p className='header-text'>
              FTD Listing
            </p>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText='Select Date Range'
              onChange={(update) => {
                setDateRange(update);
                const isnull = update.filter((date) => date === null)

                if (isnull.length === 0) {
                  const formattedStartDate = format(update[0], 'yyyy-MM-dd');
                  const formattedEndDate = format(update[1], 'yyyy-MM-dd');
                  const filtered = data.data.filter(item =>
                    isWithinInterval(format(item.date_joined, 'yyyy-MM-dd'), { start: formattedStartDate, end: formattedEndDate })
                  );

                  setindividualStatsToShowInTable(filtered);
                }
                if (!update[0] && !update[1]) {
                  setindividualStatsToShowInTable(data.data);
                }
              }}
              isClearable={true}
            />
          </div>


        </div>

        <div className='table-container'>

          {
            <>

              <IndividualStatsTableRow
                is_header_row={true}
                individualStat={{}}
                referralCode=''
              />


              {individualStatsToShowInTable && individualStatsToShowInTable.map((individualStat, index) => (
                <IndividualStatsTableRow
                  key={index}
                  is_header_row={false}
                  individualStat={individualStat}
                  referralCode={data.referralCode}
                />
              ))}



            </>

          }


        </div>
      </>

    </div>
  );
}

export default IndividualStatsTable;