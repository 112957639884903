// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesCompositeFinancials.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import { NUM_WEEKS, TIME_FRAMES } from '../../../../utils/Constants';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader'
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import { formatDateDay } from '../../../../utils/DateFormatter';

// asset imports

const CompositeFinancials = () => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  

  // state variables
  const [apiIsRunning, setApiIsRunning] = useState(false);
  const [purchaseData, setPurchaseData] = useState({}); 
  const [redemptionData, setRedemptionData] = useState({}); 
  const [revenueData, setRevenueData] = useState({}); 
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(NUM_WEEKS?.ONE)

  // use effects
  useEffect(() => {
    getPurchases(TIME_FRAMES?.DAY)
    getRedemptions(TIME_FRAMES?.DAY)
    getRevenue(TIME_FRAMES?.DAY)
  }, [])

  // api functions
  const getPurchases = async ( time_period ) => {

    setApiIsRunning(true)

    const params = {
      group_by: time_period
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_PURCHASES, params)

      setPurchaseData(response?.data?.data)
      setApiIsRunning(false)

    } catch (error) {
      setApiIsRunning(false)
      console.log("error fetching purchase data", error?.response)
    }

  }

  const getRedemptions = async ( time_period ) => {

    setApiIsRunning(true)

    const params = {
      group_by: time_period
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_PRIZE_REDEMPTIONS, params)

      setRedemptionData(response?.data?.data)
      setApiIsRunning(false)

    } catch (error) {
      setApiIsRunning(false)
      console.log("error fetching purchase data", error?.response)
    }

  }

  const getRevenue = async ( time_period ) => {

    setApiIsRunning(true)

    const params = {
      group_by: time_period
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_REVENUE, params)

      setRevenueData(response?.data?.data)
      setApiIsRunning(false)

    } catch (error) {
      setApiIsRunning(false)
      console.log("error fetching purchase data", error?.response)
    }

  }

  // helper functions 
  const handleSetTimeFrame = (value) => {
    setSelectedTimeFrame(value)
  }

  const getLastNDays = (n, dates) => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - n);
  
    return dates.filter(date => new Date(date) >= pastDate);
  };

  const getLast4WeeksData = (dates, purchaseData, redemptionData, revenueData) => {
    const weeksData = [[], [], [], []]; 
  
    dates.forEach(date => {
      const currentDate = new Date(date);
      const weekIndex = Math.floor((new Date() - currentDate) / (7 * 24 * 60 * 60 * 1000));
      if (weekIndex < 4) {
        weeksData[weekIndex].push(date);
      }
    });
  
    const aggregateData = (data, week) => 
      week.reduce((sum, date) => sum + parseFloat(data?.[date]?.amount || 0), 0);
  
    const getWeekRange = (week) => {
      if (week.length === 0) return '';
      const sortedDates = week.sort((a, b) => new Date(a) - new Date(b));
      const startDate = sortedDates[0];
      const endDate = sortedDates[sortedDates.length - 1];
      return `${formatDateDay(startDate)} - ${formatDateDay(endDate)}`;
    };
  
    const reversedWeeksData = weeksData.reverse();
  
    const labels = reversedWeeksData.map((week, index) => `Week ${index + 1} (${getWeekRange(week)})`);
  
    return {
      labels: labels,
      purchaseValues: reversedWeeksData.map(week => aggregateData(purchaseData, week)),
      withdrawValues: reversedWeeksData.map(week => aggregateData(redemptionData, week)),
      revenueValues: reversedWeeksData.map(week => aggregateData(revenueData, week)),
    };
  };

  const allDates = [
    ...new Set([
      ...Object.keys(purchaseData || {}),
      ...Object.keys(redemptionData || {}),
      ...Object.keys(revenueData || {}),
    ]),
  ];

  const filteredDates = getLastNDays(
    (
      selectedTimeFrame === NUM_WEEKS?.ONE
      ? 1
      : selectedTimeFrame === NUM_WEEKS?.TWO
      ? 2
      : selectedTimeFrame === NUM_WEEKS?.THREE
      ? 3
      : 4
    ) 
    * 7, allDates);

      let data;
      if (selectedTimeFrame === NUM_WEEKS.MONTHLY) {
        const { labels, purchaseValues, withdrawValues, revenueValues } = getLast4WeeksData(filteredDates, purchaseData, redemptionData, revenueData);
        data = {
          labels: labels, // X-axis weeks
          datasets: [
            {
              label: 'Purchases',
              data: purchaseValues,
              backgroundColor: '#6E2EF5',
              borderRadius: 10
            },
            {
              label: 'Withdrawals',
              data: withdrawValues,
              backgroundColor: '#E6533C',
              borderRadius: 10
            },
            {
              label: 'Revenue',
              data: revenueValues,
              backgroundColor: '#26BF94',
              borderRadius: 10
            },
          ],
        };
      } else {
        const purchaseValues = filteredDates.map(date => parseFloat(purchaseData?.[date]?.amount || 0));
        const withdrawValues = filteredDates.map(date => parseFloat(redemptionData?.[date]?.amount || 0));
        const revenueValues = filteredDates.map(date => parseFloat(revenueData?.[date]?.amount || 0));

        data = {
          labels: filteredDates, // X-axis dates
          datasets: [
            {
              label: 'Purchases',
              data: purchaseValues,
              backgroundColor: '#6E2EF5',
              borderRadius: 10
            },
            {
              label: 'Withdrawals',
              data: withdrawValues,
              backgroundColor: '#E6533C',
              borderRadius: 10
            },
            {
              label: 'Revenue',
              data: revenueValues,
              backgroundColor: '#26BF94',
              borderRadius: 10
            },
          ],
        };
      }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        }
      },
      y: {
        stacked: false,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        },
        afterDataLimits: (axis) => {
          axis.max = axis.max * 1.1; // Increase the max value by 10% to make space for labels
        }
      },
    },
  };



  return (
    <div className="wagers-by-day-graph">
      <div className="wagers-by-day-graph-header-row">
        <div className='left-half-graph-header'>
          <div className="wagers-by-day-graph-header-label">
            Composite Financials
          </div>
        </div>
        <div className='right-half-graph-header'>
          <DropDown 
            drop_down_options={NUM_WEEKS}
            selected_value={selectedTimeFrame}
            set_selected_value={handleSetTimeFrame}
            />
        </div>
      </div>
      <div className="wagers-graph-container">
        {apiIsRunning ? (
          <RebetLoader height={'150px'} width={'150px'} />
        ) : (redemptionData && purchaseData && revenueData) ? (
          <Bar data={data} options={options} />
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default CompositeFinancials;
