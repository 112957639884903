import React, { useEffect, useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesDepositAttempts.css';
import DepositAttemptsRow from './DepositAttemptsRow/DepositAttemptsRow';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import "react-datepicker/dist/react-datepicker.css";

function DepositAttempts({ showSearchInput = true, username = '' }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const [isApiRunning, setIsApiRunning] = useState(false)
  const [data, setData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const getDepositAttempts = async (value) => {

    setIsApiRunning(true);

    const params = { username: value }

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.USERS_DEPOSIT_ATTEMPTS, params);
      const data = response?.data?.data;

      function transformDepositAttempts(originalData) {
        const modifiedData = [];

        originalData.forEach(user => {
          const { username, attempts } = user;

          attempts.forEach(attempt => {
            modifiedData.push({
              username: username,
              deposit_attempts: attempts.length,
              attempt: attempt
            });
          });
        });

        return modifiedData;
      }

      const modifiedData = transformDepositAttempts(data);

      setData(modifiedData);
      setIsApiRunning(false);

    } catch (error) {
      setIsApiRunning(false);
      console.log("error getting Deposit Attempts", error);
    }
  };

  const handleSearchValueChange = (event) => {
    setSearchValue(event?.target?.value)
  }

  const clearSearch = () => {
    if (searchValue === '') return
    setSearchValue('')
    getDepositAttempts('')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log("Enter key pressed!");
      getDepositAttempts(searchValue);
    }
  };

  useEffect(() => {
    getDepositAttempts(username)
  }, [])

  return (
    <div className='deposit-table-container'>
      <div className="tou-table">
        <>
          <div className='head-controls'>

            <div className='header-text-container'>
              <p className='header-text'>
                Deposit Attempts
              </p>
              {showSearchInput &&
                <div className='search-input'>
                  <input
                    type="text"
                    placeholder="Search user..."
                    className="users-searchInput"
                    value={searchValue}
                    onChange={handleSearchValueChange}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    onClick={clearSearch}>
                  </button>
                </div>
              }
            </div>


          </div>

          <div className='table-container'>

            {
              isApiRunning
                ? (
                  <RebetLoader
                    height={'100px'}
                    width={'100px'} />
                )
                : (
                  <>

                    <DepositAttemptsRow
                      is_header_row={true}
                      data={{}}
                    />


                    {data && data.map((data, index) => (
                      <DepositAttemptsRow
                        key={index}
                        is_header_row={false}
                        data={data}
                      />
                    ))}
                  </>
                )
            }


          </div>
        </>

      </div>
    </div>
  )
}

export default DepositAttempts;