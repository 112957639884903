import React, { useState } from 'react';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../api/ApiRoutes';

import './stylesAddNewEventPopup.css';
import { X } from 'react-feather';

function AddNewEventPopup({ isVisible, onClosePressed, getEventCodesList }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const email = JSON.parse(localStorage.getItem('email'));

  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)

  const [eventCode, setEventCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const buttonDisabled = eventCode.length <= 0 ? true : false;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (eventCode.length < 8) {
      return;
    }

    setIsSubmitting(true);

    try {
      const params = { event_code: eventCode, created_by: email };
      await PrivateAdminPanelInstance?.post(API_ROUTES?.ADD_EVENT_CODE, params);

    } catch (error) {
      console.log("Error adding event code:", error);
    } finally {
      
      setIsSubmitting(false);
      setEventCode('');

      onClosePressed();
      await getEventCodesList();
    }
  };

  const handleClosePressed = () => {
    onClosePressed();
    setEventCode('');
  }

  if(!isVisible) return null;
  return (
    <div className='add-new-event-popup'>
      <div className='add-new-event-panel' >
        <div className='add-new-event-panel-header'>
          <p>Add Event Code</p>
          <button
            className='close-button'
            onClick={handleClosePressed}
          >
            <X
              className='close-icon'
            />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="eventCode">Event Code:</label>
            <input
              type="text"
              id="eventCode"
              value={eventCode}
              placeholder='Enter 8 digit event code'
              onChange={(e) => setEventCode(e.target.value)}
              maxLength="8"
              required
            />
          </div>
          <button type="submit" disabled={buttonDisabled} className={`submit-button ${buttonDisabled ? 'disabled' : null}`}>
            {isSubmitting ? 'Creating Event...' : 'Create Event'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default AddNewEventPopup;