import React from 'react';
import './stylesDepositAttemptsRow.css';
import { formatDateDay, formatFromUnixTimestamp } from '../../../../../utils/DateFormatter'; // A função 'formatDatesArray' não é usada
import moneyFormatter from '../../../../../utils/MoneyFormatter';

function DepositAttemptsRow({
  is_header_row,
  data,
}) {
  if (data?.last_evaluated_key) return null;

  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Username" :
              data?.username
          }
        </p>
      </div>
      <div className='cell-small extra-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Attempts" :
              data?.deposit_attempts
          }
        </p>
      </div>
      <div className='cell-small extra-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Date" :
              formatFromUnixTimestamp(data?.attempt.created_at)
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label reason'>
          {
            is_header_row ?
              "Reason" :
              data?.attempt.gateway_response_reason
          }
        </p>
      </div>
      <div className='cell-small extra-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Amount" :
              `$ ${moneyFormatter(data?.attempt.amount)}`
          }
        </p>
      </div>

    </div>
  );
}

export default DepositAttemptsRow;
