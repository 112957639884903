import React from 'react';

import './stylesTOURow.css';
import { formatDateDay, formatDatesArray } from '../../../../../utils/DateFormatter';
import { DATE_RANGE } from '../../../../../utils/Constants';

function TOURow({
  is_header_row,
  data,
}) {
  if (data.last_evaluated_key) return
  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Username" :
              data?.username
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "User ID" :
              data?.user_id
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Location" :
              data?.location
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Date" :
              formatDateDay(data?.date, DATE_RANGE.DAY)
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Time" :
              data?.time
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Status" :
              data?.status ? "Accepted" : "Not Accepted"
          }
        </p>
      </div>
    </div>
  )
}

export default TOURow;