import React, { useEffect, useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesPlaidStatusByDay.css'
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { formatDatesArray } from '../../../../utils/DateFormatter';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import getLastXDays from '../../../../utils/GetLastXDays';
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import { DATE_RANGE, TIME_FRAMES } from '../../../../utils/Constants';

function PlaidStatusByDay() {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const [apiIsRunning, setIsApiRunning] = useState(false)
  const [graphData, setGraphData] = useState({});
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('Last 7 days')

  useEffect(() => {
    const getEmailStatusByDay = async () => {

      setIsApiRunning(true)

      try {
        const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_PLAID_STATUS_BY_DAY)

        const data = response?.data?.data

        setGraphData(data)
        setIsApiRunning(false)

      } catch (error) {
        setIsApiRunning(false)
        console.log("error fetching Plaid Status By Day data", error)
      }
    }

    getEmailStatusByDay()
  }, [])

  const lastXDays = getLastXDays(selectedTimeFrame, graphData);

  const labels = formatDatesArray(Object.keys(lastXDays).reverse(), TIME_FRAMES.DAY);
  const active = Object.values(lastXDays).map(value => value.active).reverse();
  const canceled = Object.values(lastXDays).map(value => value.canceled).reverse();
  const expired = Object.values(lastXDays).map(value => value.expired).reverse();
  const failed = Object.values(lastXDays).map(value => value.failed).reverse();
  const notVerified = Object.values(lastXDays).map(value => value.not_verified).reverse();
  const pendingReview = Object.values(lastXDays).map(value => value.pending_review).reverse();
  const verified = Object.values(lastXDays).map(value => value.verified).reverse();

  const data = {
    labels: labels, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'Active',
        data: active, // Y-axis values from the data
        backgroundColor: '#6E2EF5',
        borderRadius: 10
      },
      {
        label: 'Canceled',
        data: canceled, // Y-axis values from the data
        backgroundColor: '#23B7E5',
        borderRadius: 10
      },
      {
        label: 'Expired',
        data: expired, // Y-axis values from the data
        backgroundColor: '#FFFFFF',
        borderRadius: 10
      },
      {
        label: 'Failed',
        data: failed, // Y-axis values from the data
        backgroundColor: '#E6533C',
        borderRadius: 10
      },
      {
        label: 'Not Verified',
        data: notVerified, // Y-axis values from the data
        backgroundColor: '#FD6F0F',
        borderRadius: 10
      },
      {
        label: 'pending Review',
        data: pendingReview, // Y-axis values from the data
        backgroundColor: '#F5B849',
        borderRadius: 10
      },
      {
        label: 'Verified',
        data: verified, // Y-axis values from the data
        backgroundColor: '#26BF94',
        borderRadius: 10
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        },
        afterDataLimits: (axis) => {
          axis.max = axis.max * 1.1; // Increase the max value by 10% to make space for labels
        }
      },
    },
  };

  return (
    <div className='plaid-status-by-day-container'>
      <div className="plaid-status-by-day-graph">
        <div className="plaid-status-by-day-graph-header-row">
          <div className='left-half-graph-header'>
            <div className="plaid-status-by-day-graph-header-label">
              Plaid Status By Day
            </div>
            <div className='right-half-graph-header'>
              <DropDown
                drop_down_options={DATE_RANGE}
                selected_value={selectedTimeFrame}
                set_selected_value={(value) => setSelectedTimeFrame(value)}
              />
            </div>
          </div>
        </div>
        <div className="email-status-by-day-graph-container">
          {apiIsRunning ? (
            <RebetLoader height={'150px'} width={'150px'} />
          ) : graphData ? (
            <Bar data={data} options={options} />
          ) : (
            <div>No data available</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PlaidStatusByDay;