// third party imports
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesBarGraph.css'
import SortByDate from '../../../../utils/SortByDate';
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import DropdownMultipleCheckbox from '../../../CommonComponents/DropdownMultipleCheckbox/DropdownMultipleCheckbox';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';
import { formatDatesArray } from '../../../../utils/DateFormatter';
import { TIME_FRAMES } from '../../../../utils/Constants';

// component imports

// asset imports

const BarGraph = ({ title, graphData, referralCodes }) => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const [selectedReferralCode, setSelectedReferralCode] = useState({ referralCode: '', data: {} });
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const optionsCode = referralCodes;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  // api functions
  const getFTDListByDay = async (selectAll, referralCode) => {
    const params = selectAll || referralCode.length === 0 ? { all_referral_codes: true } : { list_of_referral_codes: referralCode };

    setIsApiRunning(true)

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_FTD_LIST_BY_DAY, params)
      const data = response.data.data
      setSelectedReferralCode({ referralCode, data: data })
      setIsApiRunning(false)
    } catch (error) {
      setIsApiRunning(false)
      console.log("error getting ftd list by day", error)
    }
  }

  // use effects
  useEffect(() => {
    getFTDListByDay(true, [])
  }, [referralCodes])

  // helper functions 
  const handleCheckboxChange = (selectAll, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    getFTDListByDay(selectAll, newSelectedOptions);
  };

  const sortedByDateData = SortByDate(selectedReferralCode.data);
  const formatedDate = formatDatesArray(Object.keys(sortedByDateData), TIME_FRAMES.DAY);

  const months = formatedDate;
  const ftds = Object.values(sortedByDateData).map(value => parseFloat(value));

  const data = {
    labels: months, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'FTDs',
        data: ftds,
        backgroundColor: '#FD6F0F',
        borderRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: false,
        type: 'category',
        ticks: {
          color: '#FFF',
        }
      },
      y: {
        type: 'linear',
        display: false,
        position: 'left',
        stacked: false,
        ticks: {
          color: '#FFF',
        }
      },
    },
  };

  return (
    <div className='bar-graph-container'>
      <div className="bar-graph">
        <div className="bar-graph-header-row">
          <div className="bar-graph-header-label">
            {title}
          </div>
          {!isApiRunning &&
            <DropdownMultipleCheckbox options={optionsCode}
              selectedOptions={selectedOptions} onChange={handleCheckboxChange} />
          }
        </div>
        <div className="bar-data-graph">
          {isApiRunning ? (
            <RebetLoader
              height={'70px'}
              width={'70p'}
            />
          ) :
            Object.keys(selectedReferralCode.data).length === 0 ?
              <div className='no-data-message'>
                No data available yet for {selectedReferralCode.referralCode} referral code
              </div> :
              <Bar data={data} options={options} />

          }
        </div>
      </div>
    </div>
  );
};

export default BarGraph;
