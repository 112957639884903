// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal rebet imports
import './stylesUserStatuses.css'
import { 
  determineSuspensionStatus,
  PLAID_STATUSES 
} from '../../../../utils/UserUtils';

// component imports

// asset imports


const UserStatuses = ({
  data
}) => {

  // non-state variables
  const [isSuspended, setIsSuspended] = useState(false)
  const [plaidStatus, setPlaidStatus] = useState("")
  const [emailStatus, setEmailStatus] = useState(true)
  const [achEnabled, setAchEnabled] = useState(true)
  const [newTermsAccepted, setNewTermsAccepted] = useState(true)

  // state variables
  
  // use effects
  useEffect(() => {

    console.log('DATAY', data)

    setIsSuspended(determineSuspensionStatus(data))
    setPlaidStatus(data?.plaid_reverification_status)
    setAchEnabled(data?.ach_enabled)
    setEmailStatus(data?.email_verified)
    // setNewTermsAccepted(data?.new_terms_accepted?.status)

  }, [data])

  // api functions

  // helper functions
 

  return (
    <div className="user-onboarding">

      <p className='header-text'>
        User Status
      </p>

      <div className='divider-line' />

      <div className='key-value-row'>
        <div className='key-container'>
          <p className='key-text'>
            Email Status
          </p>
        </div>
        <div className='value-container'>
          <div className={!emailStatus ? 'value-text-background-negative' : 'value-text-background-good'}>
              <div className={!emailStatus ? 'value-text-negative' : 'value-text-good'}>
              { emailStatus ? "verified" : "not verified"}
              </div>
          </div>
        </div>  
      </div>

      <div className='key-value-row'>
        <div className='key-container'>
          <p className='key-text'>
            Plaid Status
          </p>
        </div>
        <div className='value-container'>
          <div className={
                (plaidStatus === PLAID_STATUSES?.FAILED || plaidStatus === PLAID_STATUSES?.NOT_VERIFIED)
                ? 'value-text-background-negative'
                : (plaidStatus === PLAID_STATUSES?.ACTIVE || plaidStatus === PLAID_STATUSES?.PENDING_REVIEW)
                ? 'value-text-background-neutral'
                : 'value-text-background-good'
              }>
            <div className={
                (plaidStatus === PLAID_STATUSES?.FAILED || plaidStatus === PLAID_STATUSES?.NOT_VERIFIED)
                ? 'value-text-negative'
                : (plaidStatus === PLAID_STATUSES?.ACTIVE || plaidStatus === PLAID_STATUSES?.PENDING_REVIEW)
                ? 'value-text-neutral'
                : 'value-text-good'
              }>
                { plaidStatus === "pending_review" ? "pending" : plaidStatus}
              </div>
          </div>
        </div>  
      </div>

      <div className='key-value-row'>
        <div className='key-container'>
          <p className='key-text'>
            Suspended
          </p>
        </div>
        <div className='value-container'>
          <div className={isSuspended ? 'value-text-background-negative' : 'value-text-background-good'}>
            <div className={isSuspended ? 'value-text-negative' : 'value-text-good'}>
            { isSuspended ? "yes" : "no"}
            </div>
          </div>
        </div>  
      </div>

      <div className='key-value-row'>
        <div className='key-container'>
          <p className='key-text'>
            ACH Status
          </p>
        </div>
        <div className='value-container'>
          <div className={!achEnabled ? 'value-text-background-negative' : 'value-text-background-good'}>
              <div className={!achEnabled ? 'value-text-negative' : 'value-text-good'}>
              { achEnabled ? "on" : "off"}
              </div>
          </div>
        </div>  
      </div>

      <div className='key-value-row'>
        <div className='key-container'>
          <p className='key-text'>
            New TOU
          </p>
        </div>
        <div className='value-container'>
          <div className={!newTermsAccepted ? 'value-text-background-neutral' : 'value-text-background-good'}>
            <div className={!newTermsAccepted ? 'value-text-neutral' : 'value-text-good'}>
            { newTermsAccepted ? "accepted" : "pending"}
            </div>
          </div>
        </div>  
      </div>

      
    </div>
  );
};

export default UserStatuses;

