import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../../../../../../CommonComponents/ToggleSwitch/ToggleSwitch';

import './stylesEditTimeLimitsPopup.css';
import createPrivateAdminPanelInstance from '../../../../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../../../../api/ApiRoutes';
import RebetLoader from '../../../../../../CommonComponents/RebetLoader/RebetLoader';

function EditTimeLimitsPopup({ getUserPersonalLimit, limits, showPersonalLimits, userId }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)

  const [daysPerWeek, setDaysPerWeek] = useState(limits.daysPerWeek ? limits.daysPerWeek : '-');
  const [timeAmount, setTimeAmount] = useState(limits.timeAmount ? limits.timeAmount : '-');
  const [daysPerWeekSwitch, setDaysPerWeekSwitch] = useState(daysPerWeek !== '-' ? 1 : 0);
  const [timeAmountSwitch, setTimeAmountSwitch] = useState(timeAmount !== '-' ? 1 : 0);
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleDecreaseDays = () => {
    if (daysPerWeek === 1 || !daysPerWeekSwitch) return;
    setDaysPerWeek(prev => Number(prev) - 1);
  };

  const handleIncreaseDays = () => {
    if (daysPerWeek === 7 || !daysPerWeekSwitch) return;
    if (daysPerWeek === '-') {
      setDaysPerWeek(1);
      return;
    }
    setDaysPerWeek(prev => Number(prev) + 1);
  };

  const handleDecreaseAmount = () => {
    if (timeAmount === 1 || !timeAmountSwitch) return;
    setTimeAmount(prev => Number(prev) - 1);
  };

  const handleIncreaseAmount = () => {
    if (timeAmount === 24 || !timeAmountSwitch) return;
    if (timeAmount === '-') {
      setTimeAmount(1);
      return;
    }
    setTimeAmount(prev => Number(prev) + 1);
  };

  const updateSwitch1 = () => {
    if (daysPerWeekSwitch === 1) {
      setDaysPerWeek('-');
    } else {
      setDaysPerWeek(1);
    }
    setDaysPerWeekSwitch(prev => (prev === 1 ? 0 : 1));
  };

  const updateSwitch2 = () => {
    if (timeAmountSwitch === 1) {
      setTimeAmount('-');
    }
    else {
      setTimeAmount(1);
    }
    setTimeAmountSwitch(prev => (prev === 1 ? 0 : 1));
  };

  const editTimeLimits = async () => {
    if (!userId) return;
    setIsApiRunning(true)

    const params = {
      user_id: userId,
      user_personal_limit: {
        max_playing_time_per_week: daysPerWeekSwitch ? Number(daysPerWeek) : false,
        max_playing_time_per_day: timeAmountSwitch ? Number(timeAmount) : false,
      }
    }

    try {
      const response = await PrivateAdminPanelInstance.post(API_ROUTES.SET_USERS_PERSONAL_LIMIT, params)
      await getUserPersonalLimit(true);

      setIsApiRunning(false)
      setSuccessMessage('Limits updated successfully');

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);

    } catch (error) {
      console.log('Error getting user personal limits: ', error)
    }

    setIsApiRunning(false)
  }

  return (
    <div className='editing-mode time-limits'>
      {successMessage && <div className='success-message'>{successMessage}</div>}
      <div className='personal-limits-section-container'>
        <div className='personal-limits-section-body'>
          <div className='personal-limits-section-item border-bottom'>
            <div className='personal-limits-section-item-label'>
              Max numbers of days you play <br /> the games per week
              <ToggleSwitch status={daysPerWeekSwitch} eventCode={() => { }} updateEventCode={updateSwitch1} />
            </div>
            <div className='personal-limits-section-item-value'>
              <div className={`time-limit-decrease ${daysPerWeekSwitch === 0 ? 'disabled' : ''}`} onClick={handleDecreaseDays}> - </div>
              <span className={`${daysPerWeekSwitch === 0 ? 'disabled' : ''}`}>{daysPerWeek}</span>
              <div className={`time-limit-increase ${daysPerWeekSwitch === 0 ? 'disabled' : ''}`} onClick={handleIncreaseDays}> + </div>
            </div>
          </div>
          <div className='personal-limits-section-item border-bottom'>
            <div className='personal-limits-section-item-label'>
              Max time you spend playing per day <br />
              <ToggleSwitch status={timeAmountSwitch} eventCode={() => { }} updateEventCode={updateSwitch2} />
            </div>
            <div className='personal-limits-section-item-value'>
              <div className={`time-limit-decrease ${timeAmountSwitch === 0 ? 'disabled' : ''}`} onClick={handleDecreaseAmount}> - </div>
              <span className={`${timeAmountSwitch === 0 ? 'disabled' : ''}`}>{timeAmount}</span>
              <div className={`time-limit-increase ${timeAmountSwitch === 0 ? 'disabled' : ''}`} onClick={handleIncreaseAmount}> + </div>
            </div>
          </div>
          <div className='buttons'>
            <button className='close-button' onClick={showPersonalLimits}>Back</button>
            <button
              className={`save-button ${isApiRunning ? 'saving' : ''}`}
              disabled={isApiRunning}
              onClick={editTimeLimits}>
              {isApiRunning ? <RebetLoader
                height={'20px'}
                width={'20px'}
              /> : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditTimeLimitsPopup;