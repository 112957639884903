// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesUserKPIs.css'

// component imports
import TotalNumUsers from './TotalNumUsers/TotalNumUsers';
import TotalEmailVerified from './TotalEmailVerified/TotalEmailVerified';
import TotalPlaidVerified from './TotalPlaidVerified/TotalPlaidVerified';
import EmailVerificationsByDay from './EmailVerificationsByDay/EmailVerificationsByDay';
import GetEmailStatusByDay from './GetEmailStatusByDay/GetEmailStatusByDay';
import PlaidStatusByDay from './PlaidStatusByDay/PlaidStatusByDay';
import TOUTable from './TOUTable/TOUTable';

// asset imports

const UserKPIs = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
 

  return (
    <div className="user-kpis">

      <div className='onboarding-metrics-row'>

        <div className='total-and-email-column'>
          <TotalNumUsers />
          <TotalEmailVerified />
        </div>

        <TotalPlaidVerified />
      </div>
      
      {/* <EmailVerificationsByDay /> */}
      <GetEmailStatusByDay />
      <PlaidStatusByDay />
      <TOUTable />
    </div>
  );
};

export default UserKPIs;