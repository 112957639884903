// third party imports
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';

// internal rebet imports
import './stylesTotalRedeemableAmount.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import { DATE_RANGE, TIME_FRAMES } from '../../../../utils/Constants';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader'
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import getLastXDays from '../../../../utils/GetLastXDays';
import { formatDatesArray } from '../../../../utils/DateFormatter';

// asset imports

const TotalRedeemableAmount = () => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  // state variables
  const [apiIsRunning, setApiIsRunning] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(DATE_RANGE.LAST_30_DAYS)

  // use effects
  useEffect(() => {
    getPurchases(selectedTimeFrame)
  }, [])

  // api functions
  const getPurchases = async (time_period) => {

    setApiIsRunning(true)

    const params = {
      group_by: time_period
    }

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_REDEEMABLE_AMOUT, params)

      const data = response?.data?.data
      const cleanedData = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});

      setGraphData(cleanedData)
      setApiIsRunning(false)

    } catch (error) {
      setApiIsRunning(false)
      console.log("error fetching purchase data", error)
    }

  }

  // helper functions 
  const lastXDays = getLastXDays(selectedTimeFrame, graphData);


  const labels = formatDatesArray( Object.keys(lastXDays).reverse(), TIME_FRAMES.DAY);
  const dataValues = Object.values(lastXDays).map(value => parseFloat(value)).reverse();
  const data = {
    labels: labels, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'Total Redeemable Amount',
        data: dataValues, // Y-axis values from the data
        backgroundColor: '#26BF94',
        borderColor: '#26BF94',
        borderRadius: 1
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          min: 0
        }
      }
    }
  };



  return (
    <div className="total-redeemable-graph">
      <div className="total-redeemable-graph-header-row">
        <div className='left-half-graph-header'>
          <div className="total-redeemable-graph-header-label">
            Total Redeemable Amount
          </div>
        </div>
        <DropDown
          drop_down_options={DATE_RANGE}
          selected_value={selectedTimeFrame}
          set_selected_value={(value) => setSelectedTimeFrame(value)}
        />
      </div>
      <div className="total-redeemable-graph-container">
        {apiIsRunning ? (
          <RebetLoader height={'150px'} width={'150px'} />
        ) : graphData ? (
          <Line data={data} options={options} />
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default TotalRedeemableAmount;
