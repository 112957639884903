// third party imports
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { X } from 'react-feather';

// internal rebet imports
import './stylesPaymentInstrumentsPopup.css'
import createPrivateAdminPanelInstance from '../../../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../../../utils/ErrorUtils';

// component imports
import RebetLoader from '../../../../../CommonComponents/RebetLoader/RebetLoader';
import { API_ROUTES } from '../../../../../../api/ApiRoutes';

// asset imports


const PaymentInstrumentsPopup = ({
  isVisible, 
  onClosePressed, 
  userData,
}) => {

  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  const PAYMENT_MODES = {
    DEPOSIT: "deposit",
    WITHDRAWAL: "withdrawal"
  }

  // state variables
  const [isDeleteApiRunning, setIsDeleteApiRunning] = useState(false)
  const [isFetchApiRunning, setIsFetchApiRunning] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  const [depositBanksList, setDepositBanksList] = useState([])
  const [withdrawalBanksList, setWithdrawalBanksList] = useState([])
  
  // use effects
  useEffect(() => {
    if(userData){
      fetchAllBanksOfAllTypes()
    }
  }, [userData])

  // api functions
  const deleteAllBankAccounts = async (payment_mode) => {
    setLastApiErrorMessage("")
    setLastApiCallError(false)
    setIsDeleteApiRunning(true)

    const params = {
      user_id: userData?.user_id,
      payment_mode: payment_mode
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.DELETE_ALL_PAYMENT_INSTRUMENTS, params)

      fetchAllBanksOfAllTypes()
      setIsDeleteApiRunning(false)

    } catch (error) {
      console.log("erorr deleting all banks", error)
      setLastApiErrorMessage(error?.response?.data?.message)
      setIsDeleteApiRunning(false)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  const retrieveUsersPaymentInstruments = async (payment_mode) => {

    setIsFetchApiRunning(true)

    const params = {
      user_id: userData?.user_id, 
      payment_mode: payment_mode
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_PAYMENT_INSTRUMENTS, params)

      if (payment_mode === PAYMENT_MODES?.DEPOSIT) {
        setDepositBanksList(response?.data?.data)
      } else {
        setWithdrawalBanksList(response?.data?.data)
      }

      setIsFetchApiRunning(false)

    } catch (error) {
      setIsFetchApiRunning(false)
      handleExpiredTokenError(navigate, error?.response)
      console.log(`Error fethcing ${payment_mode} bank accounts`, error?.response)
    }
  }

  const deleteSpecificBankAccount = async (payment_mode, source_id) => {
    setLastApiErrorMessage("")
    setLastApiCallError(false)
    setIsDeleteApiRunning(true)

    const params = {
      user_id: userData?.user_id, 
      payment_mode: payment_mode, 
      source_id: source_id
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.DELETE_SPECIFIC_PAYMENT_INSTRUMENT, params)

      fetchAllBanksOfAllTypes()
      setIsDeleteApiRunning(false)

    } catch (error) {
      console.log("erorr deleting all banks", error)
      setLastApiErrorMessage(error?.response?.data?.message)
      setIsDeleteApiRunning(false)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  // helper functions
  const fetchAllBanksOfAllTypes = () => {
    retrieveUsersPaymentInstruments(PAYMENT_MODES?.DEPOSIT)
    retrieveUsersPaymentInstruments(PAYMENT_MODES?.WITHDRAWAL)
  }

  const handleClosePressed = () => {
    onClosePressed()
  }

  const handleDeletePressed = () => {
      deleteAllBankAccounts(PAYMENT_MODES?.DEPOSIT)
      deleteAllBankAccounts(PAYMENT_MODES?.WITHDRAWAL)
  }

 
  if(!isVisible) return null;
  return (
    <div className="payment-instruments-popup">

      <div className='payment-instruments-panel'>

      <div className='payment-instruments-header'>

        <div className='payment-instruments-header-label'>
         Edit {userData?.username}'s Payment Methods
        </div>

        <button 
        className='close-button'
        onClick={handleClosePressed}
        >
          <X 
          className='close-icon'
          />
        </button>
      </div>

      <div className='banks-container'>

        <div className='bank-header-row'>
          Deposit
        </div>

        {
          isFetchApiRunning 
          ? (
            <RebetLoader 
            height={'70px'}
            width={'70p'}
            />
          ) : (
            <>
            {depositBanksList.map((item, index) => (
              <div className='bank-row' key={index}>
              
                <div className='bank-label'>
                  {item?.bank_provider}
                </div>

                <div className='delete-one-bank-button-container'>
                  <button 
                  className='delete-one-bank-button'
                  onClick={() => deleteSpecificBankAccount(PAYMENT_MODES?.DEPOSIT, item?.source_id)}
                  >
                      {
                          isDeleteApiRunning
                          ? (
                            <RebetLoader 
                            height={'20px'}
                            width={'20px'}
                            />
                          )
                          : "Delete"
                        }
                  </button>
                </div>

              </div>
            ))}
            </>
          )
        }

       

      </div>

      <div className='banks-container'>

        <div className='bank-header-row'>
          Withdrawal
        </div>

        {
            isFetchApiRunning 
            ? (
              <RebetLoader 
              height={'70px'}
              width={'70p'}
              />
            ) : (
              <>
              {withdrawalBanksList.map((item, index) => (
                <div className='bank-row' key={index}>
                
                  <div className='bank-label'>
                    {item?.bank_provider}
                  </div>

                  <div className='delete-one-bank-button-container'>
                    <button 
                    className='delete-one-bank-button'
                    onClick={() => deleteSpecificBankAccount(PAYMENT_MODES?.WITHDRAWAL, item?.source_id)}
                    >
                        {
                          isDeleteApiRunning
                          ? (
                            <RebetLoader 
                            height={'20px'}
                            width={'20px'}
                            />
                          )
                          : "Delete"
                        }
                    </button>
                  </div>

                </div>
              ))}
              </>
            )
          }

       
        

      </div>

      <div className='payment-instruments-button-container'>
          <button 
            className="delete-button"
            onClick={handleDeletePressed}
          >
            {
              isDeleteApiRunning
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : "Delete All"
            }
          </button>
      </div>

      {
        lastApiCallError 
        ? (
          <div className='delete-error-message'>
            {lastApiErrorMessage}
          </div>
        ) 
        : null
      }

      </div>
      
    </div>
  );
};

export default PaymentInstrumentsPopup;

