const timeRangeMap = {
  'Last 7 days': 7,
  'Last 21 days': 21,
  'Last 30 days': 30,
  'Last 45 days': 45,
}

const getLastXDays = (days, obj) => {

  const reversedKeys = Object.keys(obj).sort((a, b) => new Date(b) - new Date(a));


  const slicedKeys = reversedKeys.slice(0, timeRangeMap[days]);

  const result = {};
  slicedKeys.forEach(key => {
    result[key] = obj[key];
  });

  return result;
};

export default getLastXDays;