// third party imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// component imports
import DepositAttempts from './DepositAttempts/DepositAttempts';

// asset imports

const Transactions = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables

  // use effects'

  // api functions

  // helper functions


  return (
    <div className="user-kpis">
      <DepositAttempts />
    </div>
  );
};

export default Transactions;