// third party imports
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { X } from 'react-feather';

// internal rebet imports
import './stylesEditWalletPopup.css'
import createPrivateAdminPanelInstance from '../../../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../../../utils/ErrorUtils';

// component imports
import DropDown from '../../../../../CommonComponents/DropDown/DropDown';
import RebetLoader from '../../../../../CommonComponents/RebetLoader/RebetLoader';
import { API_ROUTES } from '../../../../../../api/ApiRoutes';

// asset imports


const EditWalletPopup = ({
  isVisible, 
  onClosePressed, 
  userData,
  refreshUserData
}) => {

  // non-state variables
  const DROPDOWN_1_OPTIONS = {
    ADD: "add",
    SUBTRACT: "subtract"
  }

  const DROPDOWN_2_OPTIONS = {
    REBET_CASH: "rebet_cash",
    REBET_COIN: "rebet_coin",
    REBET_FREE_CASH: "rebet_free_cash",
    REDEEMABLE_CASH: "redeemable_cash"
  }

  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  // state variables
  const [addSubtractValue, setAddSubstractValue] = useState("")
  const [amountToChange, setAmountToChange] = useState("")
  const [moneyType, setMoneyType] = useState("")
  const [reason, setReason] = useState("")
  const [editButtonActive, setEditButtonActive] = useState(false)
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(true)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  
  // use effects
  useEffect(() => {
    if(
      addSubtractValue?.trim() !== ""
      && amountToChange?.trim() !== ""
      && moneyType?.trim() !== ""
      && reason?.trim() !== ""
      ) {
        setEditButtonActive(true)
      }
    else {
      setEditButtonActive(false)
    }

  }, [addSubtractValue, amountToChange, moneyType, reason])

  // api functions
  const editUsersWallet = async () => {
    setIsApiRunning(true)
    setLastApiErrorMessage("")
    const params = {
      user_id: userData?.user_id,
      money_type: moneyType, 
      operation: addSubtractValue === DROPDOWN_1_OPTIONS?.ADD ? "credit" : "debit",
      amount: amountToChange?.toString(),
      reason: reason
    }

    console.log("PARAMS", params)

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.EDIT_USER_WALLET, params)

      refreshUserData()

      setIsApiRunning(false)

    } catch (error) {
      setIsApiRunning(false)
      setLastApiCallError(true)
      setLastApiErrorMessage(error?.response?.data?.message)
      console.log("error editing users wallet", error?.response)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  // helper functions
  const handleClosePressed = () => {
    onClosePressed()
  }

  const handleChangeAddSubtractValue = (value) => {
    setAddSubstractValue(value)
  }

  const handleChangeAmountToChange = (event) => {
    setAmountToChange(event?.target?.value)
  }

  const handleChasnCashType = (value) => {
    setMoneyType(value)
  }

  const handleChangeReason = (event) => {
    setReason(event?.target?.value)
  }

  const handleEditWalletPressed = () => {
    if (editButtonActive) {
      editUsersWallet()
    }
  }

 
  if(!isVisible) return null;
  return (
    <div className="edit-wallet-popup">

      <div className='edit-wallet-panel'>

        <div className='edit-wallet-header'>

          <div className='edit-wallet-header-label'>
            Edit {userData?.username}'s Wallet
          </div>

          <button 
          className='close-button'
          onClick={handleClosePressed}
          >
            <X 
            className='close-icon'
            />
          </button>
        </div>

        <div className='current-balances-container'>

          <div className='balance-block'>
            <div className='balance-value-text'>
              {parseFloat(userData?.rebet_cash)?.toFixed(2)}
            </div>
            <div className='balance-label-text'>
              Rebet Cash
            </div>
          </div>

          <div className='balance-block'>
            <div className='balance-value-text'>
            {parseFloat(userData?.rebet_coin)?.toFixed(0)}
            </div>
            <div className='balance-label-text'>
              Rebet Coin
            </div>
          </div>

          <div className='balance-block'>
            <div className='balance-value-text'>
            {parseFloat(userData?.rebet_free_cash)?.toFixed(2)}
            </div>
            <div className='balance-label-text'>
              Free Rebet Cash
            </div>
          </div>

          <div className='balance-block'>
            <div className='balance-value-text'>
              {(parseFloat(userData?.rebet_cash) - parseFloat(userData?.not_wagered_rebet_cash))?.toFixed(2)}
            </div>
            <div className='balance-label-text'>
              Redeemable Balance
            </div>
          </div>

        </div>

        <div className='edit-wallet-section-header'>

          <p className='edit-wallet-section-header-text'>
            {/* Wallet Actions */}
          </p>

        </div>

        <div className='action-statement-container'>

          <div className='action-statement-text'>
            I want to
          </div>

          <DropDown 
            drop_down_options={DROPDOWN_1_OPTIONS}
            selected_value={addSubtractValue}
            set_selected_value={handleChangeAddSubtractValue}
            />

         <input
            type="number"
            placeholder="Amount"
            className="amount-input"
            value={amountToChange}
            onChange={handleChangeAmountToChange}
          />

          <DropDown 
            drop_down_options={DROPDOWN_2_OPTIONS}
            selected_value={moneyType}
            set_selected_value={handleChasnCashType}
            />    

          <div className='action-statement-text'>
            {addSubtractValue === DROPDOWN_1_OPTIONS?.SUBTRACT  ? "from" : "to"} Connor's wallet.
          </div>  

        </div>

        <div className='action-statement-container'>

         <input
            type="text"
            placeholder="Reason..."
            className="reason-input"
            value={reason}
            onChange={handleChangeReason}
          /> 

        </div>

        {
        lastApiCallError 
        ? (
          <div className='error-message'>
            {lastApiErrorMessage}
          </div>
        ) 
        : null
      }

        <div className='submit-button-container'>
          <button 
            className={
              editButtonActive 
              ? (!isApiRunning ? 'submit-button' : 'submit-button-sending')
              : 'submit-button-inactive'
            }
            onClick={handleEditWalletPressed}
          >
            {
              isApiRunning
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : "Edit Wallet"
            }
          </button>
        </div>

      </div>
      
    </div>
  );
};

export default EditWalletPopup;

