// third party imports
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesLogIn.css'
import { NAVIGATION_ROUTES } from '../../utils/NavigationUtils';
import PublicAdminPanelInstance from '../../api/AffiliatePanelInstance';
import { API_ROUTES } from '../../api/ApiRoutes';
import AppContext from '../../context/AppContext';

// component imports
import RebetLoader from '../CommonComponents/RebetLoader/RebetLoader';

// asset imports
import rebetBrandingImage from '../../assets/branding/rebet_branding_column.png'


const LogIn = () => {
  // non-state variables
  const navigate = useNavigate();
  const { setToken, setLastLogInTimestamp } = useContext(AppContext)

  // state variables
  const [isTooNarrow, setIsTooNarrow] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [loginButtonActive, setLoginButtonActive] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  
  // use effects'
  useEffect(() => {
    const handleResize = () => {
      setIsTooNarrow(window.innerWidth <= 950);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (email && password) {
      setLoginButtonActive(true)
    } else {
      setLoginButtonActive(false)
    }
  }, [email, password])

  // api functions

  // helper functions
  const handleEmailChange = (event) => {
    setEmail(event?.target?.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event?.target?.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') handleLoginPressed();
  };

  const handleLoginPressed = async () => {

    setIsApiRunning(true)
    setLastApiCallError(false)
    setLastApiErrorMessage("")

    const params = {
      user_name: email, 
      password: password
    }

    try {

      const response = await PublicAdminPanelInstance?.post(API_ROUTES?.LOG_IN, params)

      setToken(response?.data?.data)
      setLastLogInTimestamp(new Date())

      localStorage.setItem('token', JSON.stringify(response?.data?.data));
      localStorage.setItem('email', JSON.stringify(email));

      const now = new Date()
      localStorage.setItem('lastLogInTime', now.toISOString());

      navigate(NAVIGATION_ROUTES?.USERS)

      setIsApiRunning(false)

    } catch (error) {
      console.log("Error logging in", error?.response)
      setIsApiRunning(false)
      setLastApiCallError(true)
      setLastApiErrorMessage(error?.response?.data?.message)
    }

  }
 

  return (
    <div className="container">
      {!isTooNarrow && (
        <div className="brandingColumn">
          <img src={rebetBrandingImage} className="brandingImage" />
        </div>
      )}
  
      <div className="formColumn">
        <div className="formContentContainer">
          <p className="loginHeaderText">
            Admin Log In
          </p>
  
          <p className="fieldLabelText">
            Email
          </p>
  
          <input
            type="text"
            placeholder=""
            className="fieldInput"
            value={email}
            onChange={handleEmailChange}
          />
  
          <p className="fieldLabelText">
            Password
          </p>
  
          <input
            type="password"
            placeholder=""
            className="fieldInput"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyPress}
          />
  
          <button
            className={
              isApiRunning || !loginButtonActive
              ? "loginButton-sending" 
              : "loginButton"
            }
            onClick={handleLoginPressed}
          > 
            {
              isApiRunning
              ? (
                <RebetLoader 
                  height={'50px'}
                  width={'50px'}
                />
              )
              : (
                <p className={loginButtonActive ? "loginButtonText" : "loginButtonText-inactive"}>
                  Log In
                </p>
              )
            }
          </button>

          <div className="login-error-message-container">
          {
            lastApiCallError 
              ? (
                <div className='error-message'>
                  {lastApiErrorMessage}
                </div>
              ) 
              : null
            }
          </div>

        </div>
      </div>
    </div>
  );
};

export default LogIn;

