import React, { useEffect, useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesTOUTable.css';
import TOURow from './TOURow/TOURow';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, isWithinInterval } from 'date-fns';

function TOUTable() {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const [isApiRunning, setIsApiRunning] = useState(false)
  const [data, setData] = useState([])
  const [dataToShowInTable, setDataToShowInTable] = useState([])
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange
  const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState([])
  const [currentPage, setCurrentPage] = useState(0)


  const getUsersTouStatus = async (last_evaluated_key = null, current_page = 0) => {
    const params =
      last_evaluated_key ? { last_evaluated_key } : null;

    // console.log("params", params);
    setIsApiRunning(true);

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_USERS_TOU_STATUS, params);
      const data = response?.data?.data;
      const newLastEvaluatedKey = data.length > 0 ? data[data.length - 1]?.last_evaluated_key : null;

      if (newLastEvaluatedKey) {
        setLastEvaluatedKeys((prevKeys) => {
          const updatedKeys = [...prevKeys];
          updatedKeys[current_page] = newLastEvaluatedKey;
          return updatedKeys;
        });
      }

      setData(data);
      setDataToShowInTable(data);
      setIsApiRunning(false);

    } catch (error) {
      setIsApiRunning(false);
      console.log("error getting TOU Table", error);
    }
  };

  const handleClickPrev = () => {
    if (currentPage > 0) {
      const prevPage = currentPage - 1;
      const prevKey = lastEvaluatedKeys[prevPage - 1];

      setDateRange([null, null]);
      setCurrentPage(prevPage);
      getUsersTouStatus(prevKey, prevPage);
    }
  }

  const handleClickNext = () => {
    const nextPage = currentPage + 1;
    const nextKey = lastEvaluatedKeys[nextPage - 1];

    setDateRange([null, null]);
    setCurrentPage(nextPage);
    getUsersTouStatus(nextKey, nextPage);
  }

  useEffect(() => {
    getUsersTouStatus()
  }, [])

  return (
    <div className='tou-table-container'>
      <div className="tou-table">
        <>
          <div className='head-controls'>

            <div className='header-text-container'>
              <p className='header-text'>
                Terms of Use
              </p>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                placeholderText='Select Date Range'
                onChange={(update) => {
                  setDateRange(update);
                  const isnull = update.filter((date) => date === null)

                  if (isnull.length === 0) {
                    const formattedStartDate = format(update[0], 'yyyy-MM-dd');
                    const formattedEndDate = format(update[1], 'yyyy-MM-dd');
                    const filtered = data.filter(item =>
                      isWithinInterval(new Date(item.date), { start: formattedStartDate, end: formattedEndDate })
                    );

                    setDataToShowInTable(filtered);
                  }
                  if (!update[0] && !update[1]) {
                    setDataToShowInTable(data);
                  }
                }}
                isClearable={true}
              />
            </div>


          </div>

          <div className='table-container'>

            {
              isApiRunning
                ? (
                  <RebetLoader
                    height={'100px'}
                    width={'100px'} />
                )
                : (
                  <>

                    <TOURow
                      is_header_row={true}
                      data={{}}
                    />


                    {dataToShowInTable && dataToShowInTable.map((data, index) => (
                      <TOURow
                        key={index}
                        is_header_row={false}
                        data={data}
                      />
                    ))}


                    {!isApiRunning &&
                      <div className='table-controls-container'>
                        <p
                          className={`prev-text ${currentPage === 0 ? 'disabled' : ''}`}
                          onClick={handleClickPrev}
                        >
                          Prev
                        </p>
                        <p
                          className='next-text'
                          onClick={handleClickNext}
                        >
                          Next
                        </p>
                      </div>
                    }
                  </>
                )
            }


          </div>
        </>

      </div>
    </div>
  )
}

export default TOUTable;