// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesAdminManagement.css'
import { SIDER_TABS } from '../../utils/Constants';

// component imports
import Sider from '../CommonComponents/Sider/Sider';
import Header from '../CommonComponents/Header/Header';
import AddAdmin from './AddAdmin/AddAdmin';
import EditAdmin from './EditAdmin/EditAdmin';
import Layout from '../CommonComponents/Layout/Layout';

// asset imports


const AdminManagement = () => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  const [activeTab, setActiveTab] = useState('add');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1200);

  // use effects'
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // api functions

  // helper functions


  return (
    <Layout selectedTab={SIDER_TABS?.ADMIN}>
      <div className='admin-management-body-container'>
        {isMobileView ? (
          <>
            <div className='admin-management-tabs'>
              <button onClick={() => setActiveTab('add')} className={activeTab === 'add' ? 'active' : ''}>Create Admin</button>
              <button onClick={() => setActiveTab('edit')} className={activeTab === 'edit' ? 'active' : ''}>Edit Admin</button>
            </div>
            <div className='tab-content'>
              {activeTab === 'add' && <AddAdmin />}
              {activeTab === 'edit' && <EditAdmin />}
            </div>
          </>
        ) : (
          <>
            <AddAdmin />
            <EditAdmin />
          </>
        )}
      </div>
    </Layout>
  );
};

export default AdminManagement;