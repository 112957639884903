import React, { useEffect, useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesFinancialsTable.css'
import FinancialsTableRow from '../FinancialsTableRow/FinancialsTableRow';
import DatePicker from 'react-datepicker';

import DropdownMultipleCheckbox from '../../../CommonComponents/DropdownMultipleCheckbox/DropdownMultipleCheckbox';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import { format } from 'date-fns';


function FinancialsTable({ referralCodes }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const [isApiRunning, setIsApiRunning] = useState(false)
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    getCommissionData([...newSelectedOptions]);
  };

  const options = referralCodes;

  const [dataToShowInTable, setDataToShowInTable] = useState([]);


  const getCommissionData = async (selectedOptions, startDate = null, endDate = null) => {
    if (selectedOptions.length === 0) {
      setDataToShowInTable([]);
      return;
    }

    setIsApiRunning(true);

    const params = { "list_of_referral_codes": selectedOptions };

    if (startDate === endDate && startDate !== null) {
      params.date = startDate;
    } else if (startDate && endDate) {
      params.date_range = [startDate, endDate];
    } else {
      params.year_month = format(new Date(), 'yyyy-MM');
    }

    try {
      const commissionDataResponse = await PrivateAdminPanelInstance?.post(API_ROUTES.ADMINS_TOTAL_COMMISSION_MONTH_BREAKDOWN, params)
      const commissionData = commissionDataResponse.data.data.referral_commission_data;

      const formattedData = Object.entries(commissionData).map(([key, value]) => ({
        code: key,
        ...value
      }));

      setDataToShowInTable(formattedData);

      setIsApiRunning(false);
    } catch (error) {
      setIsApiRunning(false);
      console.log("error Commission data", error);
    }
  };

  return (
    <div className="financials-table">
      <>
        <div className='head-controls'>
          <div className='header-text-container'>
            <p className='header-text'>
              Monthly Commission Breakdown
            </p>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText='Select Date Range'
              onChange={(update) => {
                setDateRange(update);
                const isnull = update.filter((date) => date === null)

                if (isnull.length === 0) {
                  const formattedStartDate = format(update[0], 'yyyy-MM-dd');
                  const formattedEndDate = format(update[1], 'yyyy-MM-dd');
                  getCommissionData(selectedOptions, formattedStartDate, formattedEndDate);
                }
                if (!update[0] && !update[1]) {
                  getCommissionData(selectedOptions, null, null);
                }
              }}
              isClearable={true}
            />
            <DropdownMultipleCheckbox options={options}
              selectedOptions={selectedOptions}
              onChange={handleCheckboxChange} />
          </div>


        </div>

        <div className='table-container'>

          {
            isApiRunning
              ? (
                <RebetLoader
                  height={'100px'}
                  width={'100px'} />
              )
              : (
                <>
                  <FinancialsTableRow
                    is_header_row={true}
                    data={{}}
                    updateEventCode={() => { }}
                  />
                  {dataToShowInTable && dataToShowInTable.map((data, index) => (
                    <FinancialsTableRow
                      key={index}
                      is_header_row={false}
                      data={data}
                      updateEventCode={() => { }}
                    />
                  ))}
                </>
              )
          }


        </div>
      </>

    </div>
  );
}

export default FinancialsTable;