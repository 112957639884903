// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { X } from 'react-feather';

// internal rebet imports
import './stylesSuspendUserPopup.css'
import createPrivateAdminPanelInstance from '../../../../../../api/PrivateAdminPanelInstance';
import { determineSuspensionStatus } from '../../../../../../utils/UserUtils';
import { handleExpiredTokenError } from '../../../../../../utils/ErrorUtils';

// component imports
import DropDown from '../../../../../CommonComponents/DropDown/DropDown';
import RebetLoader from '../../../../../CommonComponents/RebetLoader/RebetLoader';
import { API_ROUTES } from '../../../../../../api/ApiRoutes';

// asset imports


const SuspendUserPopUp = ({
  isVisible, 
  onClosePressed, 
  userData,
  refreshUserData
}) => {

  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  // state variables
  const [reason, setReason] = useState("")
  const [isSuspended, setIsSuspended] = useState(false)
  const [isSuspendButtonActive, setIsSuspendButtonActive] = useState(false)
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  
  // use effects'
  useEffect(() => {
    setIsSuspended(determineSuspensionStatus(userData))
  }, [userData])

  useEffect(() => {
    if(reason) {
      setIsSuspendButtonActive(true)
    } else {
      setIsSuspendButtonActive(false)
    }
  }, [reason])

  // api functions
  const suspendUnsuspendUser = async () => {
    setIsApiRunning(true)
    setLastApiCallError(false)
    setLastApiErrorMessage("")
    const params = {
      user_id: userData?.user_id, 
      is_suspended: isSuspended ? "0" : "1",
      reason: reason
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.SUSPEND_UNSUSPEND_USER, params)

      refreshUserData()

      setIsApiRunning(false)

    } catch (error) {
      console.log("Error suspending or unsuspending the user", error?.response)
      setIsApiRunning(false)
      setLastApiCallError(true)
      setLastApiErrorMessage(error?.response?.data?.message)
      handleExpiredTokenError(navigate, error?.response)
    }


  }

  // helper functions
  const handleClosePressed = () => {
    onClosePressed()
  }
  
  const handleChangeReason = (event) => {
    setReason(event?.target?.value)
  }

  const handleSuspendUnsuspendPressed = () => {
    if (isSuspendButtonActive) {
      suspendUnsuspendUser()
    }
  }

 
  if(!isVisible) return null;
  return (
    <div className="suspend-user-popup">

      <div className='suspend-user-panel'>

      <div className='suspend-user-header'>

        <div className='suspend-user-header-label'>
          Suspend/Unsuspend
        </div>

        <button 
        className='close-button'
        onClick={handleClosePressed}
        >
          <X 
          className='close-icon'
          />
        </button>
      </div>

      <div className='current-status-label'>
        Current Status: 
      </div>

      <div className={isSuspended ? 'suspend-value-text-background-negative' : 'suspend-value-text-background-good'}>
        <p className={ isSuspended ? 'suspend-value-text-negative' :  'suspend-value-text-good'}>
           {isSuspended ? "Suspended" : "Not Suspended"}
        </p>
      </div>

      <input
          type="text"
          placeholder="Reason..."
          className="suspend-reason-input"
          value={reason}
          onChange={handleChangeReason}
        /> 

      <div className='suspend-button-container'>
          <button 
            className={
              isSuspendButtonActive
              ? ( !isApiRunning ? 'submit-button' : 'submit-button-sending')
              : 'submit-button-inactive'
            }
            onClick={handleSuspendUnsuspendPressed}
          >
            {
              isApiRunning
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : isSuspended ? "Activate" : "Suspend"
            }
          </button>
      </div>

      {
        lastApiCallError 
        ? (
          <div className='error-message'>
            {lastApiErrorMessage}
          </div>
        ) 
        : null
      }

      </div>
      
    </div>
  );
};

export default SuspendUserPopUp;

