// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';


// internal rebet imports
import './stylesTotalPlaidVerified.css'
import { API_ROUTES } from '../../../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports

const TotalPlaidVerified = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);
  ChartJS.register(ArcElement, Tooltip, Legend);

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [canceled, setCanceled] = useState("")
  const [expired, setExpired] = useState("")
  const [pendingReview, setPendingReview] = useState("")
  const [verified, setVerified] = useState("")
  const [active, setActive] = useState("")
  const [failed, setFailed] = useState("")
  const [notVerified, setNotVerified] = useState("")

  const graphData = {
    labels: ['Cancelled', 'Expired', 'Pending Review', 'Verified', 'Active', 'Failed' ,'Not Verified'],
    datasets: [
      {
        label: false,
        data: [
          parseInt(canceled), 
          parseInt(expired),
          parseInt(pendingReview),
          parseInt(verified),
          parseInt(active),
          parseInt(failed),
          parseInt(notVerified),
        ],
        backgroundColor: [
          '#23B7E5',
          '#FFFFFF',
          '#F5B849',
          '#26BF94',
          '#6E2EF5',
          '#E6533C',
          '#FD6F0F',
        ],
        borderColor: [
          '#23B7E5',
          '#FFFFFF',
          '#F5B849',
          '#26BF94',
          '#6E2EF5',
          '#E6533C',
          '#FD6F0F',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true, // Show the legend
        position: 'right', // Position the legend at the bottom
        align: 'center', // Center the legend items
        labels: {
          boxWidth: 20, // Adjust box width for better spacing
          padding: 20,  // Add padding between legend items
        },
      },
      datalabels: {
        display: false, // Disable the labels on the wedges
      }
    },
  };
  
  // use effects
  useEffect(() => {
    fetchTotalPlaidVerificationNumbers()
  }, [])

  // api functions
  const fetchTotalPlaidVerificationNumbers = async () => {

    setIsApiRunning(true)
    
    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_PLAID_VERIFIED)

      setCanceled(response?.data?.data?.canceled)
      setExpired(response?.data?.data?.expired)
      setPendingReview(response?.data?.data?.pending_review)
      setVerified(response?.data?.data?.verified)
      setActive(response?.data?.data?.active)
      setFailed(response?.data?.data?.failed)
      setNotVerified(response?.data?.data?.not_verified)

      setIsApiRunning(false)

    } catch (error) {
      setIsApiRunning(false)
      handleExpiredTokenError(navigate, error?.response);
      console.log("error fetching total num users", error?.response)
    }

  }

  // helper functions
 

  return (
    <div className="total-plaid-verified">

      <div className='total-email-title-container'>
        Plaid Verifications
      </div>

      {
        isApiRunning
        ? (
          <RebetLoader 
          height={'50px'}
          width={'50px'}
          />
        ) : (
          <div className='total-email-graph-container'>
            <Pie data={graphData} options={options} />
          </div>
        )
      }

    </div>
  );
};

export default TotalPlaidVerified;