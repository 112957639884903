// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesCodes.css'
import { SIDER_TABS } from '../../utils/Constants';
import AffiliatePanelInstance from '../../api/AffiliatePanelInstance';

// component imports
import Sider from '../CommonComponents/Sider/Sider';
import Header from '../CommonComponents/Header/Header';
import RebetLoader from '../CommonComponents/RebetLoader/RebetLoader';
import CodesRow from './EventsRow/EventsRow';
import createPrivateAdminPanelInstance from '../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../api/ApiRoutes';
import AddNewEventPopup from './AddNewEventPopup/AddNewEventPopup';
import Layout from '../CommonComponents/Layout/Layout';

// asset imports


const Events = () => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [codesToShowInTable, setCodesToShowInTable] = useState([])
  const [isAddNewEventsPopupVisible, setIsAddNewEventsPopupVisible] = useState(false)

  // use effects

  // api functions
  const getEventCodesList = async () => {

    setIsApiRunning(true)

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_EVENT_CODES_LIST)
      const data = response?.data.data

      setCodesToShowInTable(data)
      setIsApiRunning(false)

    } catch (error) {
      setIsApiRunning(false)
      console.log("error getting event codes", error)
    }
  }

  useEffect(() => {
    getEventCodesList()
  }, [])

  const updateEventCode = async ({ event_code, is_active }) => {
    const params = {
      event_code,
      is_active
    }

    setIsApiRunning(true)

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.UPDATE_EVENT_CODE, params)
      await getEventCodesList();

      setIsApiRunning(false)

    } catch (error) {
      setIsApiRunning(false)
      console.log("error updating event codes", error)
    }
  }

  // helper functions


  return (
    <Layout selectedTab={SIDER_TABS?.EVENTS}>
      <div className='codes-body-container'>
        <AddNewEventPopup isVisible={isAddNewEventsPopupVisible} onClosePressed={setIsAddNewEventsPopupVisible} getEventCodesList={getEventCodesList} />
        <div className="codes-table">

          <>
            <div className='head-controls'>

              <div className='header-text-container'>
                <p className='header-text'>
                  Events
                </p>
                <button className='add-new-event-button' onClick={() => setIsAddNewEventsPopupVisible(true)}>Add New Event</button>
              </div>


            </div>

            <div className='table-container'>

              {
                isApiRunning
                  ? (
                    <RebetLoader
                      height={'100px'}
                      width={'100px'} />
                  )
                  : (
                    <>

                      <CodesRow
                        is_header_row={true}
                        code={{}}
                        updateEventCode={() => { }}
                      />


                      {codesToShowInTable && codesToShowInTable.map((user, index) => (
                        <CodesRow
                          key={index}
                          is_header_row={false}
                          code={user}
                          updateEventCode={updateEventCode}
                        />
                      ))}
                    </>
                  )
              }


            </div>
          </>

        </div>


      </div>
    </Layout>
  );
};

export default Events;