import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AppContext from './context/AppContext';
import { NAVIGATION_ROUTES } from './utils/NavigationUtils';

const RequireAuth = ({ children }) => {

  const token = JSON.parse(localStorage.getItem('token'));
  const storedDate = localStorage.getItem('lastLogInTime');

  const lastLogin = new Date(storedDate)
  const currentTime = new Date();
  const timeDifferenceInMinutes = (currentTime - lastLogin) / 1000 / 60;

  if (!token?.IdToken || timeDifferenceInMinutes > 60) {
    return <Navigate to={NAVIGATION_ROUTES?.LOGIN} replace />;
  }

  return children;
};

export default RequireAuth;