// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesHeader.css';
import { NAVIGATION_ROUTES } from '../../../utils/NavigationUtils';

// component imports


// asset imports
// import logo from '../../../assets/icons/ic_logo.svg';
import logo from '../../../assets/branding/ic_logo.svg';
import menu from '../../../assets/icons/ic_menu.svg'

const Header = ({ isExpanded, setIsExpanded }) => {
  // non-state variables
  const navigate = useNavigate();
  const email = localStorage.getItem('email');


  // state variables

  // use effects'

  // api functions

  // helper functions
  const handleUserIconClicked = () => {
    navigate(NAVIGATION_ROUTES?.LOGIN)
  }

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="header">
      {/* Content for the header can go here */}
      <div className='sider-top-icons'>
        <img
          className='menu-icon'
          alt='logo'
          src={menu}
          width={24}
          height={24}
          onClick={toggleExpanded}
        />
        <img
          className='logoImage'
          alt='logo'
          src={logo}
          width={40}
          height={40}
          onClick={() => navigate(NAVIGATION_ROUTES?.USERS)}
        />
      </div>

      <button
        className='user-button'
        onClick={handleUserIconClicked}
      >

        <p className='user-button-text'>
          {email[1]}
        </p>

      </button>
    </div>
  );
};

export default Header;

