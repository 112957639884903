// third party imports
import React, { useState, useEffect } from 'react';
import { X } from 'react-feather';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesToggleACHPopup.css'
import createPrivateAdminPanelInstance from '../../../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../../../api/ApiRoutes';
import { handleExpiredTokenError } from '../../../../../../utils/ErrorUtils';

// component imports
import RebetLoader from '../../../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports


const ToggleACHPopup = ({
  isVisible, 
  onClosePressed, 
  userData, 
  refreshUserData
}) => {

  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  // state variables
  const [achStatus, setAchStatus] = useState(true)
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("There was an incredibly bad super terrible error with this process. Burn your computer.")
  
  // use effects
  useEffect(() => {
    setAchStatus(userData?.ach_enabled)
  }, [userData])

  // api functions
  const toggleUserAch = async () => {

    setIsApiRunning(true)
    setLastApiCallError(false)
    setLastApiErrorMessage("")

    const params = {
      user_id: userData?.user_id, 
      ach_enabled: !achStatus
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.CHANGE_USER_ACH, params)

      refreshUserData()

      setIsApiRunning(false)

    } catch (error) {
      console.log("error toggeling ach", error?.response)
      setIsApiRunning(false)
      setLastApiErrorMessage(error?.response?.data?.message)
      setLastApiCallError(true)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  // helper functions
  const handleClosePressed = () => {
    onClosePressed()
  }

  const toggleACHPressed = () => {
    toggleUserAch()
  }

 
  if(!isVisible) return null;
  return (
    <div className="toggle-ach-popup">

      <div className='toggle-ach-panel'>

      <div className='toggle-ach-header'>

        <div className='toggle-ach-header-label'>
         Toggle ACH
        </div>

        <button 
        className='close-button'
        onClick={handleClosePressed}
        >
          <X 
          className='close-icon'
          />
        </button>
      </div>

      <div className='current-status-label'>
        Current Status: 
      </div>

      <div className={achStatus ? 'toggle-ach-value-text-background-good' : 'toggle-ach-value-text-background-negative'}>
        <p className={achStatus ? 'toggle-ach-value-text-good' : 'toggle-ach-value-text-negative'}>
          {
            achStatus ? "ACH On" : "ACH Off"
          }
        </p>
      </div>

      <div className='toggle-ach-button-container'>
          <button 
            className={!isApiRunning ? 'submit-button' : 'submit-button-sending'}
            onClick={toggleACHPressed}
          >
            {
              isApiRunning
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : (achStatus ? "Turn Off" : "Turn On")
            }
          </button>
      </div>

      {
        lastApiCallError 
        ? (
          <div className='error-message'>
            {lastApiErrorMessage}
          </div>
        ) 
        : null
      }

      </div>
      
    </div>
  );
};

export default ToggleACHPopup;

