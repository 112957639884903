import React from 'react';
import { formatFromUnixTimestamp } from '../../../utils/DateFormatter';

import './stylesEventsRow.css';
import ToggleSwitch from '../../CommonComponents/ToggleSwitch/ToggleSwitch';

function EventsRow({
  is_header_row,
  code,
  updateEventCode
 }) {
  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Code" :
              code?.code
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Created By" :
              code?.created_by
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Updated At" :
              formatFromUnixTimestamp(code?.updated_at)
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Status" :
              <ToggleSwitch status={code.is_active} eventCode={code.code} updateEventCode={updateEventCode} />
          }
        </p>
      </div>
    </div>
  )
}

export default EventsRow;