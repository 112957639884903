// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesNumSportradarByDay.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';
import { API_ROUTES } from '../../../../api/ApiRoutes';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader'

// asset imports

const NumSportradarByDay = () => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const [apiIsRunning, setApiIsRunning] = useState(false);
  const [graphData, setGraphData] = useState(null); // Initialize as null to check later

  useEffect(() => {
    getSportRadarBetMetrics();
  }, []);

  const refreshSportRadarBetMetrics = async () => {
    setApiIsRunning(true);
    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.REFRESH_SPORTRADAR_BET_METRICS);
    } catch (error) {
      handleExpiredTokenError(navigate, error?.response);
      console.log("There was an error fetching the Sportradar betting metrics");
    } finally {
      setApiIsRunning(false);
    }
  }

  const getSportRadarBetMetrics = async () => {
    await refreshSportRadarBetMetrics()
    setApiIsRunning(true);
    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_SPORTRADAR_BET_METRICS);
      console.log("response", response);
      const data = createStackedBarData(response?.data?.data?.sportradar_rejected, response?.data?.data?.sportradar_accepted);
      setGraphData(data);
    } catch (error) {
      handleExpiredTokenError(navigate, error?.response);
      console.log("There was an error fetching the Sportradar betting metrics");
    } finally {
      setApiIsRunning(false);
    }
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${parseInt(month)}/${parseInt(day)}`;
  };

  const createStackedBarData = (rejected_data = {}, accepted_data = {}) => {
    if (!rejected_data || !accepted_data) {
      return {
        labels: [],
        datasets: [],
        totalValues: [],
      };
    }

    const sortedDates = Object.keys(rejected_data).sort((a, b) => {
      const [dayA, monthA, yearA] = a.split('-').map(Number);
      const [dayB, monthB, yearB] = b.split('-').map(Number);
      return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
    });

    const labels = sortedDates.map(date => formatDate(date));
    const rejectedValues = sortedDates.map(date => parseFloat(rejected_data[date]?.count) || 0);
    const acceptedValues = sortedDates.map(date => parseFloat(accepted_data[date]?.count) || 0);
    const totalValues = rejectedValues.map((value, index) => (value + acceptedValues[index]).toLocaleString());

    return {
      labels,
      datasets: [
        {
          label: 'Accepted',
          data: acceptedValues,
          backgroundColor:  '#26BF94',
          borderRadius: 10
        },
        {
          label: 'Rejected',
          data: rejectedValues,
          backgroundColor:  '#E6533C',
          borderRadius: 10
        },
      ],
      totalValues,
    };
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: function(context) {
          // Only display data labels for the top dataset (rejected data)
          return context.dataset.label === 'Rejected';
        },
        color:  '#FFF',
        anchor: 'end',
        align: 'end',
        formatter: function(value, context) {
          const total = graphData?.totalValues?.[context.dataIndex] || 0;
          return total;
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        }
      },
    },
  };

  return (
    <div className="bets-by-day-graph">
      <div className="bets-by-day-graph-header-row">
        <div className="bets-by-day-graph-header-label">
          Num Sportradar Bets By Day
        </div>
      </div>
      <div className="bets-graph-container">
        {apiIsRunning ? (
          <RebetLoader height={'150px'} width={'150px'} />
        ) : graphData ? (
          <Bar data={graphData} options={options} />
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default NumSportradarByDay;
