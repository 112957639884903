// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesTotalNumUsers.css'
import { API_ROUTES } from '../../../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports
import GraphicUsersIcon from '../../../../assets/graphic_icons/gi_users.png'

const TotalNumUsers = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [totalNumUsers, setTotalNumUsers] = useState("")
  
  // use effects
  useEffect(() => {
    fetchTotalNumUsers()
  }, [])

  // api functions
  const fetchTotalNumUsers = async () => {

    setIsApiRunning(true)
    
    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_NUM_USERS)

      setTotalNumUsers(response?.data?.data?.total_number_of_users?.toLocaleString())
      setIsApiRunning(false)

    } catch (error) {
      setIsApiRunning(false)
      handleExpiredTokenError(navigate, error?.response);
      console.log("error fetching total num users", error?.response)
    }

  }

  // helper functions
 

  return (
    <div className="total-num-users">

      {
        isApiRunning
        ? (
          <RebetLoader 
          height={'50px'}
          width={'50px'}
          />
        ) : (
          <>
            <div className='total-num-users-left-half'>

              <img 
              src={GraphicUsersIcon}
              className='total-users-icon'
              />

            </div>

            <div className='total-num-users-right-half'>
              <div className='total-users-label'>
                Total Users
              </div>
              <div className='total-users-value'>
                {totalNumUsers}
              </div>
            </div>
          </>
        )
      }

    </div>
  );
};

export default TotalNumUsers;