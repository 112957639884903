// third party imports
import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesTransactions.css'
import SortByDate from '../../../../utils/SortByDate';
import { formatDatesArray } from '../../../../utils/DateFormatter';

// component imports

// asset imports

const Transactions = ({ graphData, title, color, formatterType }) => {
  // non-state variables

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  // state variables

  // use effects

  // api functions

  // helper functions 


  const sortedByDateData = SortByDate(graphData);

  const labels = formatDatesArray(Object.keys(sortedByDateData), formatterType);
  const count = Object.values(sortedByDateData).map(value => parseFloat(value.count));
  const amount = Object.values(sortedByDateData).map(value => parseFloat(value.amount));

  const dataAmount = {
    labels: labels, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'Amount',
        data: amount,
        backgroundColor: color,
        borderRadius: 10,
        yAxisID: 'y'
      },
    ],
  };

  const dataCount = {
    labels: labels, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'Count',
        data: count,
        backgroundColor: color,
        borderRadius: 10,
        yAxisID: 'y'
      },
    ],
  };

  const amountOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: false,
        type: 'category',
        ticks: {
          color: '#FFF',  // Define a cor dos rótulos do eixo x
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        stacked: false,
        ticks: {
          color: '#FFF',  // Define a cor dos rótulos do eixo y
        },
        title: {
          display: true,
          text: 'Amount'
        },
      },
    },
  };

  const countOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: false,
        type: 'category',
        ticks: {
          color: '#FFF',  // Define a cor dos rótulos do eixo x
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        stacked: false,
        ticks: {
          color: '#FFF',  // Define a cor dos rótulos do eixo y
        },
        title: {
          display: true,
          text: 'Count'
        },
      },
    },
  };

  return (
    <div className='transactions-by-day-container'>
      <div className="transactions-by-day-graph">
        <div className="transactions-by-day-graph-header-row">
          <div className=''>
            <div className="transactions-by-day-graph-header-label">
              {title} - Amount
            </div>
          </div>
        </div>
        <div className="transactions-by-day-graph-container">
          <Bar data={dataAmount} options={amountOptions} />
        </div>
      </div>
      <div className="transactions-by-day-graph">
        <div className="transactions-by-day-graph-header-row">
          <div className=''>
            <div className="transactions-by-day-graph-header-label">
              {title} - Count
            </div>
          </div>
        </div>
        <div className="transactions-by-day-graph-container">
          <Bar data={dataCount} options={countOptions} />
        </div>
      </div>
    </div>
  );
};

export default Transactions;
