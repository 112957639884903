// src/utils/validation.js

/**
 * Validates the RebetCoins value based on certain conditions.
 *
 * @param {int} value - The value to be validated.
 * @param {boolean} switchStatus - The status of the switch.
 * @param {function} setError - The function to set the error message.
 * @returns {boolean} - Returns true if the validation passes, otherwise false.
 */
export const validateRebetCoins = (value, switchStatus, setError) => {
  if ((switchStatus && (value < 50 || value > 50000)) || (switchStatus && !value)) {
    setError('RebetCoins value must be between 50 and 50000');
    return false;
  }
  setError('');
  return true;
};

/**
 * Validates the RebetCash value based on certain conditions.
 *
 * @param {int} value - The value to be validated.
 * @param {boolean} switchStatus - The status of the switch.
 * @param {function} setError - The function to set the error message.
 * @returns {boolean} - Returns true if the validation passes, otherwise false.
 */
export const validateRebetCash = (value, switchStatus, setError) => {
  if ((switchStatus && (value < 10 || value > 5000)) || (switchStatus && !value)) {
    setError('RebetCash value must be between 10 and 5000');
    return false;
  }
  setError('');
  return true;
};
