// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal rebet imports
import './stylesUserData.css'

// component imports

// asset imports


const UserData = ({
  data
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
  const formatDate = (date_string) => {

    const months = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];

     // Create a Date object from the string
    const date = new Date(date_string);

    // Get the day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Helper function to add ordinal suffix to day
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; 
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }

      return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
  }
 

  return (
    <div className="user-data">
      
      <p className='header-text'>
        General Info
      </p>

      <div className='divider-line' />

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            User Id
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
            {data?.user_id}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            Username
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {data?.username}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            Email
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {data?.email}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            Referral Code Used
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {data?.referral_code}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            Referral Code Own
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {data?.referral_code_own}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            Full Name
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {data?.first_name} {data?.last_name}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            Phone 
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {data?.phone_number}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            State 
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {data?.registration_state}
          </p>
        </div>  
      </div>

      <div className='data-key-value-row'>
        <div className='data-key-container'>
          <p className='data-key-text'>
            Sign Up Data 
          </p>
        </div>
        <div className='data-value-container'>
          <p className='data-value-text'>
          {formatDate(data?.date_joined)}
          </p>
        </div>  
      </div>

    </div>
  );
};

export default UserData;

