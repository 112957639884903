// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal rebet imports
import './stylesKPIHeader.css'
import { KPI_HEADER_TABS } from '../../../utils/Constants';

// component imports

// asset imports


const KPIHeader = ({
  selectedTab,
  setSelectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }
 

  return (
    <div className='panel-header'>

      <button 
        className={selectedTab === KPI_HEADER_TABS?.USERS ? 'header-tab-selected': 'header-tab'}
        onClick={() => handleSetSelectedTab(KPI_HEADER_TABS?.USERS)}
      >
        <p className={selectedTab === KPI_HEADER_TABS?.USERS ? 'tab-text-selected': 'tab-text'}>
          Users
        </p>
      </button>

      <button 
        className={selectedTab === KPI_HEADER_TABS?.BETS ? 'header-tab-selected': 'header-tab'}
        onClick={() => handleSetSelectedTab(KPI_HEADER_TABS?.BETS)}
      >
        <p className={selectedTab === KPI_HEADER_TABS?.BETS ? 'tab-text-selected': 'tab-text'}>
          Bets
        </p>
      </button>

      <button 
        className={selectedTab === KPI_HEADER_TABS?.FINANCIALS ? 'header-tab-selected': 'header-tab'}
        onClick={() => handleSetSelectedTab(KPI_HEADER_TABS?.FINANCIALS)}
      >
        <p className={selectedTab === KPI_HEADER_TABS?.FINANCIALS ? 'tab-text-selected': 'tab-text'}>
          Financials
        </p>
      </button>

      <button 
        className={selectedTab === KPI_HEADER_TABS?.TRANSACTIONS ? 'header-tab-selected': 'header-tab'}
        onClick={() => handleSetSelectedTab(KPI_HEADER_TABS?.TRANSACTIONS)}
      >
        <p className={selectedTab === KPI_HEADER_TABS?.TRANSACTIONS ? 'tab-text-selected': 'tab-text'}>
          Transactions
        </p>
      </button>

      <button 
        className={selectedTab === KPI_HEADER_TABS?.STATES ? 'header-tab-selected': 'header-tab'}
        onClick={() => handleSetSelectedTab(KPI_HEADER_TABS?.STATES)}
      >
        <p className={selectedTab === KPI_HEADER_TABS?.STATES ? 'tab-text-selected': 'tab-text'}>
          States
        </p>
      </button>

    </div>
  );
};

export default KPIHeader;

