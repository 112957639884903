// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesUserRow.css'
import { NAVIGATION_ROUTES } from '../../../../../utils/NavigationUtils';
import { 
  PLAID_STATUSES, 
  determineSuspensionStatus 
} from '../../../../../utils/UserUtils';

// component imports

// asset imports
import EyeIcon from '../../../../../assets/icons/ic_eye_orange.png'
import EditIcon from '../../../../../assets/icons/ic_edit_green.png'
import WalletIcon from '../../../../../assets/icons/ic_wallet_green.png'
import DeleteIcon from '../../../../../assets/icons/ic_delete_red.png'

const UserRow = ({
  is_header_row, 
  user_data,
  onDeleteUserPressed
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  const [isSuspended, setIsSuspended] = useState(false)
  const [plaidStatus, setPlaidStatus] = useState("")
  
  // use effects
  useEffect(() => {

    setIsSuspended(determineSuspensionStatus(user_data))
    setPlaidStatus(user_data?.re_plaid_verification_status)

  }, [user_data])

  // api functions

  // helper functions
  const handleViewUserClicked = () => {
    navigate(NAVIGATION_ROUTES?.USER_MANAGER, {
      state: { 
        user_data: user_data, 
        wallet_active: false 
      }
    })
  }

  const handleUserWalletPressed = () => {
    navigate(NAVIGATION_ROUTES?.USER_MANAGER, {
      state: { 
        user_data: user_data, 
        wallet_active: true 
      }
    })
  }

  const handleDeleteUserPressed = () => {
    onDeleteUserPressed(user_data)
  }

  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>

      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
            "Username" :
            user_data?.username
          }
        </p>
      </div>

      <div className='cell-big'>
        <p className='cell-label'>
            {
              is_header_row ?
              "Email" :
              user_data?.email
            }
        </p>
      </div>

      <div className='cell-biggest'>
        <p className='cell-label'>
          {
            is_header_row ?
            "UserId" :
            user_data?.user_id
          }
        </p>
      </div>

      <div className='cell-small'>
        <div className={
          is_header_row 
          ? "cell-label" 
          : isSuspended
          ? 'value-text-background-negative'
          : 'value-text-background-good'}>
          <div className={
            is_header_row
            ? "cell-label"
            : isSuspended 
            ? "value-text-negative"
            : "value-text-good "
          }>
          {
            is_header_row ?
            "Suspended" :
            ( !isSuspended ? "no" : "yes")
          }
          </div>
        </div>
      </div>

      <div className='cell-small'>
        <div className={
          is_header_row 
          ? "cell-label" 
          : (plaidStatus === PLAID_STATUSES?.FAILED || plaidStatus === PLAID_STATUSES?.NOT_VERIFIED)
          ? 'value-text-background-negative'
          : (plaidStatus === PLAID_STATUSES?.ACTIVE || plaidStatus === PLAID_STATUSES?.PENDING_REVIEW)
          ? 'value-text-background-neutral'
          : 'value-text-background-good'
          }>
           <div className={
            is_header_row 
            ? "cell-label" 
            : (plaidStatus === PLAID_STATUSES?.FAILED || plaidStatus === PLAID_STATUSES?.NOT_VERIFIED)
            ? 'value-text-negative'
            : (plaidStatus === PLAID_STATUSES?.ACTIVE || plaidStatus === PLAID_STATUSES?.PENDING_REVIEW)
            ? 'value-text-neutral'
            : 'value-text-good'
            }>
          {
            is_header_row ?
            "Plaid Status" 
            : plaidStatus === PLAID_STATUSES?.PENDING_REVIEW
            ? "pending"
            : user_data?.plaid_reverification_status
          }
          </div>
        </div>
      </div>

      <div className='cell-big'>
        {is_header_row ? (
          <p className='cell-label'>
            Actions
          </p>
        ) : (
          <div className='actions-container'>
            <button
              className='view-user-button'
              onClick={handleViewUserClicked}
            >
              <img 
                className='button-icon'
                src={EyeIcon}
              />
            </button>

            <button
              className='edit-user-button'
              onClick={handleUserWalletPressed}
            >
              <img 
                className='button-icon'
                src={WalletIcon}
              />
            </button>

            <button
              className='delete-user-button-small'
              onClick={handleDeleteUserPressed}
            >
              <img 
                className='button-icon'
                src={DeleteIcon}
              />
            </button>
          </div>
        )
      }
      </div>

    </div>
  );
};

export default UserRow;

