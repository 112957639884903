import React from 'react';

import './stylesToggleSwitch.css';

function ToggleSwitch({ status, eventCode, updateEventCode }) {

  const handleChangeStatus = (e) => {
    updateEventCode({event_code: eventCode, is_active: e.target.checked})
  }

  return (
    <label className="switch">
      <input type="checkbox" checked={status} onChange={(e) => handleChangeStatus(e)} />
      <span className="slider round"></span>
    </label>
  )
}

export default ToggleSwitch;