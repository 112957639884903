import { validateRebetCoins, validateRebetCash } from '../Validation';

/**
 * Handles the change of the RebetCash value.
 * @param {object} values - The values object.
 * @param {function} setRebetCash - The function to set the RebetCash value.
 * @param {boolean} cashSwitch - The status of the cash switch.
 * @param {function} setRebetCashError - The function to set the RebetCash error message.
 */
export const handleRebetCashChange = (values, setRebetCash, cashSwitch, setRebetCashError) => {
  const { floatValue } = values;
  setRebetCash(floatValue);
  validateRebetCash(floatValue, cashSwitch, setRebetCashError);
};

/**
 * Handles the change of the RebetCoins value.
 * @param {object} values - The values object.
 * @param {function} setRebetCoins - The function to set the RebetCoins value.
 * @param {boolean} coinSwitch - The status of the coin switch.
 * @param {function} setRebetCoinsError - The function to set the RebetCoins error message.
 */
export const handleRebetCoinChange = (values, setRebetCoins, coinSwitch, setRebetCoinsError) => {
  const { floatValue } = values;
  setRebetCoins(floatValue);
  validateRebetCoins(floatValue, coinSwitch, setRebetCoinsError);
};

/**
 * Updates the coin switch status.
 * @param {function} setCoinSwitch - The function to set the coin switch status.
 * @param {function} setRebetCoinsError - The function to set the RebetCoins error message.
 */
export const updateCoinSwitch = (setCoinSwitch, setRebetCoinsError) => {
  setCoinSwitch(prev => !prev);
  setRebetCoinsError('');
};

/**
 * Updates the cash switch status.
 * @param {function} setCashSwitch - The function to set the cash switch status.
 * @param {function} setRebetCashError - The function to set the RebetCash error message.
 */
export const updateCashSwitch = (setCashSwitch, setRebetCashError) => {
  setCashSwitch(prev => !prev);
  setRebetCashError('');
};
