import React from 'react';

import './stylesFinancialsTableRow.css'

function FinancialsTableRow({
  is_header_row,
  data,
  updateEventCode
}) {
  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Referral Code" :
              data?.code
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Cpa" :
              data?.cpa
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Num Owed Referrals" :
              data?.num_owed_referrals
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Cpa Owed" :
              data?.cpa_owed
          }
        </p>
      </div>
    </div>
  );
}

export default FinancialsTableRow;