import { AppColors } from "../../utils/AppColors";

export const themeStyles = 
   {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: AppColors?.black,
      },
      text: {
        fontSize: '24px',
        textAlign: 'center', 
        color: AppColors?.white,
        fontWeight: 'bold',
        fontFamily: '"Sora", sans-serif',
        marginTop: '20px',
        maxWidth: '90vw'
      },
      image: {
        width: '220px', 
        height: '220px'
      }
};
