import React from 'react';

import './stylesFinancialsStats.css'
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

function FinancialsStats({ financialsStats, isApiRunning }) {
  const stats = ['Total Commission Paid Out', 'Monthly Commission Owed', 'Avg. CAC', 'Total Revenue']
  return (
    <div className='financials-stats-container'>
      {
        isApiRunning
          ? (
            <RebetLoader
              height={'100px'}
              width={'100px'} />
          )
          :
          stats.map((stat, index) => {
            return (
              <div key={index} className='financials-stat'>
                <h2 className='stat-title'>{stat}</h2>
                <div className='stat-value'>
                  {
                    index === 0 ? `$${financialsStats?.totalCommissionPaidOut ?? '0'}` :
                      index === 1 ? `$${financialsStats?.monthlyCommissionOwed ?? '0'}` :
                        index === 2 ? `$${financialsStats?.avgCac ?? '0'}` :
                          index === 3 ? `$${financialsStats?.totalRevenue ?? '0'}` : null
                  }
                </div>
              </div>
            )
          })
      }
    </div>
  )
}


export default FinancialsStats;