// third party imports
import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesCashInOut.css'

// component imports

// asset imports

const CashInOut = ({ states, selectedState, handleSelectedState, cashIn, cashOut }) => {
  // non-state variables

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  // state variables

  // use effects

  // api functions

  // helper functions 

  return (
    <div className='cash-in-out-container'>
      <div className="cash-in-graph">
        <h2>Cash In</h2>
        <p>Count: <span>{cashIn.count}</span></p>
        <p>Amount: <span>{parseFloat(cashIn.amount)}</span></p>
      </div>
      <div className="cash-in-graph">
        <h2>Cash Out</h2>
        <p>Count: <span>{cashOut.count}</span></p>
        <p>Amount: <span>{parseFloat(cashOut.amount)}</span></p>
      </div>
    </div>
  );
};

export default CashInOut;
