// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesKPIs.css'
import { SIDER_TABS } from '../../utils/Constants';
import { KPI_HEADER_TABS } from '../../utils/Constants';

// component imports
import Sider from '../CommonComponents/Sider/Sider';
import Header from '../CommonComponents/Header/Header';
import KPIHeader from './KPIHeader/KPIHeader'
import BetKPIs from './BetKPIs/BetKPIs';
import UserKPIs from './UserKPIs/UserKPIs';
import FinancialKPIs from './FinancialKPIs/FinancialKPIs';
import TransactionsByState from './TransactionsByState/TransactionsByState';
import Transactions from './Transactions/Transactions';
import Layout from '../CommonComponents/Layout/Layout';

// asset imports


const KPIs = () => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  const [selectedTab, setSelectedTab] = useState(KPI_HEADER_TABS?.USERS)

  // use effects'

  // api functions

  // helper functions
  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }


  return (
    <Layout selectedTab={SIDER_TABS?.KPIS}>
      <div className='kpi-body-container'>

        <KPIHeader
          selectedTab={selectedTab}
          setSelectedTab={handleSetSelectedTab}
        />

        {
          selectedTab === KPI_HEADER_TABS?.BETS
            ? (
              <BetKPIs />
            ) : selectedTab === KPI_HEADER_TABS?.USERS
              ? (
                <UserKPIs />
              ) : selectedTab === KPI_HEADER_TABS?.FINANCIALS
                ? (
                  <FinancialKPIs />
                ) : selectedTab === KPI_HEADER_TABS?.TRANSACTIONS
                  ? (
                    <Transactions />
                  ) : selectedTab === KPI_HEADER_TABS?.STATES
                    ? (
                      <TransactionsByState />
                    ) :
                    (
                      null
                    )
        }

      </div>
    </Layout>
  );
};

export default KPIs;