export const initialState = {
  affiliateKpis: [
    { name: 'Total Sign Ups', number: '', percentage: '', up: false },
    { name: 'Monthly Sign Ups', number: '', percentage: '', up: false },
    { name: 'Total FTDs', number: '', percentage: '', up: false },
    { name: 'Monthly FTDs', number: '', percentage: '', up: false },
    { name: 'Total Deposit Ratio', number: '', percentage: '', up: false },
    { name: 'Monthly Deposit Ratio', number: '', percentage: '', up: false },
  ],
  topPerformers: [],
  isApiRunning: false,
};

export const SET_KPI = 'SET_KPI';
export const SET_TOP_PERFORMERS = 'SET_TOP_PERFORMERS';
export const SET_TOTAL_SIGNUPS_YEAR = 'SET TOTAL SIGNUPS YEAR';
export const SET_IS_API_RUNNING = 'SET_IS_API_RUNNING';

export const affiliateKpisReducer = (state, action) => {
  switch (action.type) {
    case SET_KPI:
      return {
        ...state,
        affiliateKpis: state.affiliateKpis.map((kpi, index) =>
          index === action.payload.index ? { ...kpi, ...action.payload.data } : kpi
        ),
      };
    case SET_TOP_PERFORMERS:
      return {
        ...state,
        topPerformers: action.payload,
      };
    case SET_IS_API_RUNNING:
      return {
        ...state,
        isApiRunning: action.payload,
      };
    default:
      return state;
  }
};