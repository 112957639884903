const stateNameMap = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

function normalizeAndMergeStates(transactions) {
  const mergedData = {};

  Object.entries(transactions).forEach(([stateAbbreviation, data]) => {
    const state = stateNameMap[stateAbbreviation] || stateAbbreviation;


    if (!mergedData[state]) {
      mergedData[state] = {
        by_day: {},
        cash_in: { count: "0", amount: "0" },
        by_month: {},
        cash_out: { count: "0", amount: "0" },
      };
    }


    Object.entries(data.by_day).forEach(([date, dayData]) => {
      if (!mergedData[state].by_day[date]) {
        mergedData[state].by_day[date] = { ...dayData };
      } else {
        mergedData[state].by_day[date].count = (
          parseInt(mergedData[state].by_day[date].count) +
          parseInt(dayData.count)
        ).toString();
        mergedData[state].by_day[date].amount = (
          (parseFloat(mergedData[state].by_day[date].amount) || 0) +
          parseFloat(dayData.amount || 0)
        ).toFixed(2);
      }
    });


    mergedData[state].cash_in.count = (
      parseInt(mergedData[state].cash_in.count) +
      parseInt(data.cash_in.count)
    ).toString();
    mergedData[state].cash_in.amount = (
      (parseFloat(mergedData[state].cash_in.amount) || 0) +
      parseFloat(data.cash_in.amount || 0)
    ).toFixed(2);


    Object.entries(data.by_month).forEach(([month, monthData]) => {
      if (!mergedData[state].by_month[month]) {
        mergedData[state].by_month[month] = { ...monthData };
      } else {
        mergedData[state].by_month[month].count = (
          parseInt(mergedData[state].by_month[month].count) +
          parseInt(monthData.count)
        ).toString();
        mergedData[state].by_month[month].amount = (
          (parseFloat(mergedData[state].by_month[month].amount) || 0) +
          parseFloat(monthData.amount || 0)
        ).toFixed(2);
      }
    });


    mergedData[state].cash_out.count = (
      parseInt(mergedData[state].cash_out.count) +
      parseInt(data.cash_out.count)
    ).toString();
    mergedData[state].cash_out.amount = (
      (parseFloat(mergedData[state].cash_out.amount) || 0) +
      parseFloat(data.cash_out.amount || 0)
    ).toFixed(2);
  });

  return mergedData;
}

export default normalizeAndMergeStates;