import React from "react";
import { Check } from "react-feather";

import './stylesCheckBox.css'

const CheckBox = ({
    height, 
    width,
    border_radius,
    isSelected, 
    onClicked
}) => {

    const handleClicked = () => {
        onClicked()
    }

    return (
        <button 
            style={{
                height: height, 
                width: width,
                borderRadius: border_radius,
                outline: "none",
                border: "solid",
                backgroundColor: isSelected ? '#9c4810' : 'transparent',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: isSelected ? "#FD6F0F" : "#777777"
             }}
             onClick={handleClicked}
        >

             {
                isSelected 
                ?  (
                <Check 
                className="check-icon"
                />
                )
                : null
            }

        </button>
    );
}

export default CheckBox;