// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesBetKPIs.css'

// component imports
import NumSportradarByDay from './NumSportradarByDay/NumSportradarByDay';
import SportradarWagersByDay from './SportradarWagersByDay/SportradarWagersByDay';

// asset imports

const BetKPIs = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
 

  return (
    <div className="bet-kpis">

        <NumSportradarByDay />

        <SportradarWagersByDay />

    </div>
  );
};

export default BetKPIs;