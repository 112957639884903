// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';


// internal rebet imports
import './stylesTopPerformers.css'

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports

const TopPerformers = ({ topPerformers, isApiRunning }) => {
  // non-state variables
  const navigate = useNavigate();
  ChartJS.register(ArcElement, Tooltip, Legend);

  // state variables

  const labels = topPerformers.map((performer) => performer.referral_code);
  const data = topPerformers.map((performer) => performer.monthly_ftds);

  const graphData = {
    labels,
    datasets: [
      {
        label: false,
        data,
        backgroundColor: [
          '#23B7E5',
          '#FFFFFF',
          '#F5B849',
          '#26BF94',
          '#6E2EF5',
        ],
        borderColor: [
          '#23B7E5',
          '#FFFFFF',
          '#F5B849',
          '#26BF94',
          '#6E2EF5',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'center',
        labels: {
          boxWidth: 20,
          padding: 20,
        },
      },
      datalabels: {
        display: false, // Disable the labels on the wedges
      }
    },
  };

  // use effects

  // api functions

  // helper functions


  return (
    <div className="top-performers">

      <div className='top-performers-title'>
        Top Performers
      </div>

      {
        isApiRunning
          ? (
            <RebetLoader
              height={'50px'}
              width={'50px'}
            />
          ) : (
            <div className='top-performers-graph-container'>
              <Pie data={graphData} options={options} />
            </div>
          )
      }

    </div>
  );
};

export default TopPerformers;