// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesSportsbook.css'
import { SIDER_TABS } from '../../utils/Constants';
import { SPORTSBOOK_HEADER_TABS } from '../../utils/Constants';

// component imports
import Sider from '../CommonComponents/Sider/Sider';
import Header from '../CommonComponents/Header/Header';
import SportsbookHeader from './SportsbookHeader/SportsbookHeader';

// asset imports


const Sportsbook = () => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  const [selectedTab, setSelectedTab] = useState(SPORTSBOOK_HEADER_TABS?.LEAGUES)
  
  // use effects'

  // api functions

  // helper functions
  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }
 

  return (
    <div className="pageContainer">
      <Sider 
      selectedTab={SIDER_TABS?.SPORTSBOOK}
      />
      <div className="contentContainer">
        <Header />

        <div className='sportsbook-body-container'>

        <SportsbookHeader 
          selectedTab={selectedTab}
          setSelectedTab={handleSetSelectedTab}
        />

        </div>
        

      </div>
    </div>
  );
};

export default Sportsbook;