// third party imports
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';

// internal rebet imports
import './stylesLineGraph.css'
import SortByDate from '../../../../utils/SortByDate';
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import DropdownMultipleCheckbox from '../../../CommonComponents/DropdownMultipleCheckbox/DropdownMultipleCheckbox';
import { color } from 'chart.js/helpers';
import { format, getYear, parse } from 'date-fns';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

// component imports

// asset imports

const LineGraph = ({ title, referralCodes }) => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const optionsCode = referralCodes;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [ltvData, setLtvData] = useState({});
  const [isApiRunning, setIsApiRunning] = useState(false)

  const handleCheckboxChange = (selectAll, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    getLtvData(selectAll, newSelectedOptions);
  };

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


  const getLtvData = async (selectAll, options) => {

    setIsApiRunning(true);

    const params = {}

    if (options.length === 1 && !selectAll) {
      params.referral_code = options[0];
    } else if (options.length > 1 && !selectAll) {
      params.list_of_referral_codes = options;
    } else {
      params.all_referral_codes = true;
    }

    try {
      const avgMonthlyLtvResponse = await PrivateAdminPanelInstance?.post(API_ROUTES.AVG_MONTHLY_LTV, params)

      const avgMonthlyLtvData = avgMonthlyLtvResponse?.data.data;
      const currentYear = getYear(new Date()).toString();

      const filteredAvgMonthlyData = Object.fromEntries(
        Object.entries(avgMonthlyLtvData).filter(([key]) => key.startsWith(currentYear))
      );

      setLtvData(filteredAvgMonthlyData);

      setIsApiRunning(false);
    } catch (error) {
      setIsApiRunning(false);
      console.log("error getting AVG LTV data", error);
    }
  };

  useEffect(() => {
    getLtvData(true, []);
  }, [referralCodes]);


  const sortedByDateData = SortByDate(ltvData);

  const avgMonthlyLtv = Object.keys(sortedByDateData);
  const formattedData = avgMonthlyLtv.map((date) => {
    const parsedDate = parse(date, 'yyyy-MM', new Date());
    return format(parsedDate, 'MMM');
  });
  const ftds = Object.values(sortedByDateData).map(value => parseFloat(value));

  const data = {
    labels: formattedData, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'Avg Life Time Value (LTV)',
        data: ftds,
        backgroundColor: '#FD6F0F',
        borderColor: '#FD6F0F',
        borderRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    }
  };


  return (
    <div className='bar-graph-container'>
      <div className="bar-graph">
        <div className="bar-graph-header-row">
          <div className="bar-graph-header-label">
            {title}
          </div>
          {!isApiRunning &&
            <DropdownMultipleCheckbox options={optionsCode}
              selectedOptions={selectedOptions} onChange={handleCheckboxChange} />
          }
        </div>
        {
          isApiRunning
            ? (
              <RebetLoader
                height={'100px'}
                width={'100px'} />
            )
            : (
              <>
                <div className="bar-data-graph">
                  <Line data={data} options={options} />
                </div>
              </>
            )
        }
      </div>
    </div>
  );
};

export default LineGraph;

