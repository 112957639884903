import React from 'react';

import './stylesIndividualStatsTableRow.css'
import { format } from 'date-fns';
import { formatDateDay } from '../../../../../utils/DateFormatter';

function IndividualStatsTableRow({
  is_header_row,
  individualStat,
  referralCode
}) {

  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Date Joined" :
              formatDateDay(format(individualStat?.date_joined, 'yyyy-MM-dd'))
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Email" :
              individualStat?.email
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Referral Code" :
              referralCode
          }
        </p>
      </div>
      <div className='cell-small'>
        <p className='cell-label'>
          {
            is_header_row ?
              "Money Type" :
              individualStat?.money_type
          }
        </p>
      </div>
    </div>
  );
}

export default IndividualStatsTableRow;