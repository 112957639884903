// third party imports
import React from 'react'

// internal rebet imports
import { themeStyles } from './stylesMobileSplashScreen'

// component imports


// asset imports
import AppIcon from '../../assets/branding/rebet_logo_with_shadow.png'

const MobileSplashScreen = () => {
    // non-state variables


    // state variables


    // use effects


    // api functions


    // helper functions


    // modal functions
    return (
    <div style={themeStyles?.container}>

        <img src={AppIcon} alt="Desktop Only" style={themeStyles?.image} />

        <h1 style={themeStyles?.text}>
            Unfortunately, this application is not current available on mobile devices, or destops below a certain width. 
        </h1>

        <h1 style={themeStyles?.text}>
            Please open in a larger window to continue!
        </h1>
    </div>
    )
}

export default MobileSplashScreen;
