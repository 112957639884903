import React, { useEffect, useState } from 'react';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

import './stylesFTDListingTable.css'
import FTDListingTableRow from './FTDListingTableRow/FTDListingTableRow';
import DatePicker from 'react-datepicker';
import { format, isWithinInterval } from 'date-fns';
import DropdownMultipleCheckbox from '../../../CommonComponents/DropdownMultipleCheckbox/DropdownMultipleCheckbox';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import DropDown from '../../../CommonComponents/DropDown/DropDown';
// import FTDListingTableRow from '../FTDListingTableRow/FTDListingTableRow';

function FTDListingTable({ referralCodes }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const options = referralCodes;

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [selectedReferralCode, setSelectedReferralCode] = useState('HENRY');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [ftdListToShowInTable, setFtdListToShowInTable] = useState([]);
  const [ftdListData, setFtdListData] = useState([]);


  const getFtdList = async (referralCode) => {
    const params = {
      referral_code: referralCode
    }

    setIsApiRunning(true)

    try {
      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_FTD_LIST, params);

      const ftdListResponse = response;
      const ftdList = ftdListResponse.data.data;

      setFtdListData(ftdList);
      setFtdListToShowInTable(ftdList);
      setSelectedReferralCode(referralCode);

      setIsApiRunning(false);

    } catch (error) {
      setIsApiRunning(false);
      console.error("error getting referral codes", error);
    }

  }


  const handleCheckboxChange = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
  };


  useEffect(() => {
    getFtdList('HENRY');
  }, [])

  return (
    <div className="ftd-listing-table">

      <>
        <div className='head-controls'>

          <div className='header-text-container'>
            <p className='header-text'>
              FTD Listing
            </p>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText='Select Date Range'
              onChange={(update) => {
                setDateRange(update);
                const isnull = update.filter((date) => date === null)

                if (isnull.length === 0) {
                  const formattedStartDate = format(update[0], 'yyyy-MM-dd');
                  const formattedEndDate = format(update[1], 'yyyy-MM-dd');
                  const filtered = ftdListData.filter(item =>
                    isWithinInterval(format(item.date_joined, 'yyyy-MM-dd'), { start: formattedStartDate, end: formattedEndDate })
                  );

                  setFtdListToShowInTable(filtered);
                }
                if (!update[0] && !update[1]) {
                  setFtdListToShowInTable(ftdListData);
                }
              }}
              isClearable={true}
            />
            <DropDown
              drop_down_options={referralCodes}
              selected_value={selectedReferralCode}
              set_selected_value={(e) => getFtdList(e)}
            />
          </div>


        </div>

        <div className='table-container'>

          {
            isApiRunning
              ? (
                <RebetLoader
                  height={'100px'}
                  width={'100px'} />
              )
              : (
                <>

                  <FTDListingTableRow
                    is_header_row={true}
                    individualStat={{}}
                  />


                  {ftdListToShowInTable && ftdListToShowInTable.map((individualStat, index) => (
                    <FTDListingTableRow
                      key={index}
                      is_header_row={false}
                      individualStat={individualStat}
                    />
                  ))}



                </>
              )
          }


        </div>
      </>

    </div>
  );
}

export default FTDListingTable;