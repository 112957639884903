// third party imports
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { X } from 'react-feather';

// internal rebet imports
import './stylesDeleteUserPopup.css'
import createPrivateAdminPanelInstance from '../../../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../../../utils/ErrorUtils';
import { API_ROUTES } from '../../../../../../api/ApiRoutes';

// component imports
import CheckBox from '../../../../../CommonComponents/CheckBox/CheckBox';
import RebetLoader from '../../../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports


const DeleteUserPopup = ({
  isVisible, 
  onClosePressed, 
  userData,
  refreshUserData
}) => {

  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  // state variables
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false)
  const [reason, setReason] = useState("")
  const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(false)
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  const [lastApiCallSuccess, setLastApiCallSuccess] = useState(false)
  const [lastApiCallSuccessMessage, setLastApiCallSuccessMessage] = useState("")
  
  // use effects
  useEffect(() => {
    if (
      isCheckBoxChecked && reason?.trim() !== ""
    ) {
      setIsDeleteButtonActive(true)
    } else {
      setIsDeleteButtonActive(false)
    }
  }, [isCheckBoxChecked, reason])

  // api functions
  const deleteUser = async () => {
    setLastApiErrorMessage("")
    setLastApiCallSuccessMessage("")
    setLastApiCallError(false)
    setLastApiCallSuccess(true)

    setIsApiRunning(true)
    const params = {
      username: userData?.username
    }

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.DELETE_USER, params)

      setLastApiCallSuccess(true)
      setLastApiCallSuccessMessage("The user was deleted successfully!")
      refreshUserData()
      setIsApiRunning(false)

    } catch (error) {
      setLastApiErrorMessage(error?.response?.data?.message)
      setIsApiRunning(false)
      console.log("There was an error deleting the user", error?.response)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  // helper functions
  const handleClosePressed = () => {
    onClosePressed()
  }

  const handleChangeReason = (event) => {
    setReason(event?.target?.value)
  }

  const handleDeletePressed = () => {
    if (isDeleteButtonActive) {
      deleteUser()
    }
  }

  const handleCheckBoxPressed = () => {
    setIsCheckBoxChecked(!isCheckBoxChecked)
  }

 
  if(!isVisible) return null;
  return (
    <div className="delete-user-popup">

      <div className='delete-user-panel'>

      <div className='delete-user-header'>

        <div className='delete-user-header-label'>
         Delete {userData?.username}'s Account
        </div>

        <button 
        className='close-button'
        onClick={handleClosePressed}
        >
          <X 
          className='close-icon'
          />
        </button>
      </div>

        <input
          type="text"
          placeholder="Reason..."
          className="delete-reason-input"
          value={reason}
          onChange={handleChangeReason}
        /> 


      <div className='delete-check-container'>

        <CheckBox
          onClicked={handleCheckBoxPressed}
          isSelected={isCheckBoxChecked}
          width={'30px'}
          height={'30px'}
          border_radius={'8px'}
        />

        <div className='delete-check-text'>
          I understand that this is permanent.
        </div>

      </div>


      <div className='delete-user-button-container'>
          <button 
            className={
              isDeleteButtonActive 
              ? (!isApiRunning ? 'delete-button' : 'delete-button-sending')
              : 'delete-button-inactive'
            }
            onClick={handleDeletePressed}
          >
            {
              isApiRunning
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : "Delete"
            }
          </button>
      </div>

      {
        lastApiCallError 
        ? (
          <div className='delete-error-message'>
            {lastApiErrorMessage}
          </div>
        ) 
        : null
      }

      {
        lastApiCallSuccess 
        ? (
          <div className='delete-success-message'>
            {lastApiCallSuccessMessage}
          </div>
        ) 
        : null
      }

      </div>
      
    </div>
  );
};

export default DeleteUserPopup;

