// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal rebet imports
import './stylesUserManagerHeader.css'
import { USER_HEADER_TABS } from '../../../../utils/Constants';

// component imports

// asset imports


const UserManagerHeader = ({
  selectedTab,
  setSelectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }
 

  return (
    <div className='panel-header'>

      <button 
        className={selectedTab === USER_HEADER_TABS?.OVERVIEW ? 'header-tab-selected': 'header-tab'}
        onClick={() => setSelectedTab(USER_HEADER_TABS?.OVERVIEW)}
      >
        <p className={selectedTab === USER_HEADER_TABS?.OVERVIEW ? 'tab-text-selected': 'tab-text'}>
          Overview
        </p>
      </button>

      <button 
        className={selectedTab === USER_HEADER_TABS?.TRANSACTIONS ? 'header-tab-selected' : 'header-tab'}
        onClick={() => setSelectedTab(USER_HEADER_TABS?.TRANSACTIONS)}
      >
        <p className={selectedTab === USER_HEADER_TABS?.TRANSACTIONS ? 'tab-text-selected': 'tab-text'}>
          Wallet
        </p>
      </button>

      <button 
        className={selectedTab === USER_HEADER_TABS?.BETS ? 'header-tab-selected' : 'header-tab'}
        onClick={() => setSelectedTab(USER_HEADER_TABS?.BETS)}
      >
        <p className={selectedTab === USER_HEADER_TABS?.BETS ? 'tab-text-selected': 'tab-text'}>
          Bets
        </p>
      </button>

    </div>
  );
};

export default UserManagerHeader;

