import axios from 'axios';
import { ADMIN_PANEL_BACKEND_BASE_URL } from '../utils/Constants';

const createPrivateAdminPanelInstance = (bearerToken) => {
    const instance = axios.create({
        baseURL: ADMIN_PANEL_BACKEND_BASE_URL
    });

    // Add a request interceptor
    instance.interceptors.request.use(
        (config) => {
            if (bearerToken) {
                config.headers.Authorization = `Bearer ${bearerToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return instance;
};

export default createPrivateAdminPanelInstance;
