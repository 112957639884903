// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesBetTable.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';

// component imports
import DropDown from '../../../CommonComponents/DropDown/DropDown';
import BetRow from './BetRow/BetRow';
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';
import EditBetPopup from './EditBetPopup/EditBetPopup';

// asset imports
import SearchIcon from '../../../../assets/icons/ic_search.png'


const BetTable = ({
  userData
}) => {
  // non-state variables

  const SEARCH_OPTIONS = {
    STRAIGHT: "straight",
    GROUP: "group",
    P2P: "p2p"
   }

  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  // state variables
  const [searchValue, setSearchValue] = useState('')
  const [searchBetType, setSearchBetType] = useState('')
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})
  const [betsList, setBetsList] = useState([])
  const [betsToShowInTable, setBetsToShowInTable] = useState([])
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [isEditBetPopupVisible, setIsEditBetPopupVisible] = useState(false)
  const [selectedBetData, setSelectedBetData] = useState({})
  
  // use effects
  useEffect(() => {
    getUsersBets()
  }, [userData])

  // api functions
  const getUsersBets = async (refresh = false) => {
    setIsApiRunning(true)

    let params = {}

    if (refresh) {
       params = {
        user_id: userData?.user_id
       }
    } else {
      params = {
        user_id: userData?.user_id,
        last_evaluated_key: lastEvaluatedKey
      }
    }

    try {

      console.log("PARAMS", params)

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_USER_BETS, params)

      const all_bets = [...betsList, ...response?.data?.data?.items]
      const all_bets_sorted = all_bets?.sort((a, b) => {
        return parseFloat(b?.created_at) - parseFloat(a.created_at);
      });
      const all_unique_bets_sorted = Array.from(
        new Map(all_bets_sorted.map(bet => [bet?.bet_id, bet])).values()
      );


      setBetsList(all_unique_bets_sorted)
      setBetsToShowInTable(all_unique_bets_sorted)
      setLastEvaluatedKey(response?.data?.data?.last_evaluated_key)

      setIsApiRunning(false)

    } catch (error) {
      console.log("error fetching users bets", error?.response)
      setIsApiRunning(false)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  const searchBet = async () => {

    setIsApiRunning(true)

    const params = {
      bet_id: searchValue,
      bet_type: searchBetType === SEARCH_OPTIONS?.STRAIGHT 
                ? "1" 
                : searchBetType === SEARCH_OPTIONS?.GROUP
                ? "5"
                : "2"
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_BET_BY_ID, params)

      setBetsToShowInTable([response?.data?.data])

      setIsApiRunning(false)

    } catch (error) {
      console.log("error searching for bet", error?.response)
      setIsApiRunning(false)
      handleExpiredTokenError(navigate, error?.response)
    }
  }

  // helper functions
  const handleSearchValueChange = (event) => {
    setSearchValue(event?.target?.value)
  }

  const handleChangeSearchBetType = (value) => {
    setSearchBetType(value)
  }

  const handleSearchPressed = () => {
    searchBet()
  }

  const handleMoreBetsPressed = () => {
    getUsersBets()
  }


  const handleEditBetPressed = (bet_data) => {
    setSelectedBetData(bet_data)
    setIsEditBetPopupVisible(true)
  }

  const refreshBets = () => {
    setTimeout(() => {
      getUsersBets(true)
    }, 1500)
  }

  return (
    <div className="user-table">

      <div className='head-controls'>

        <div className='header-text-container'>
            <p className='header-text'>
              User's Bets
            </p>
        </div>

        <input
            type="text"
            placeholder="Search bets..."
            className="bet-searchInput"
            value={searchValue}
            onChange={handleSearchValueChange}
          />

        <div className='drop-down-container'>
          <DropDown 
          drop_down_options={SEARCH_OPTIONS}
          selected_value={searchBetType}
          set_selected_value={handleChangeSearchBetType}
          />
        </div>

        <button
          className='search-button'
          onClick={handleSearchPressed}
        >
          <img 
            className='search-icon'
            src={SearchIcon}
          />
        </button>

      </div>

      <div className='table-container'>
        <BetRow 
        is_header_row={true}
        bet_data={{}}
        onEditBetPressed={handleEditBetPressed}
        />

      {
        isApiRunning
        ? (
          <RebetLoader 
          height={'100px'}
          width={'100px'}
          />
        ) : (
          <div className='data-rows-container'>
          {betsToShowInTable && betsToShowInTable.map((bet, index) => (
                  <BetRow
                    key={bet?.bet_id}
                    is_header_row={false}
                    bet_data={bet}
                    onEditBetPressed={handleEditBetPressed}
                  />
                ))}
          </div>
        )
      }
        

        

        <EditBetPopup 
            isVisible={isEditBetPopupVisible}
            onClosePressed={() => setIsEditBetPopupVisible(false)}
            betData={selectedBetData}
            refreshBets={refreshBets}
            />
      

      </div>
      {!isApiRunning &&
        <div className='table-controls-container'>
            <p className='next-text' onClick={handleMoreBetsPressed}> More Bets</p>
        </div>
      }
    </div>
  );
};

export default BetTable;

