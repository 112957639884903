// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';


// internal rebet imports
import './stylesTotalEmailVerified.css'
import { API_ROUTES } from '../../../../api/ApiRoutes';
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';

// asset imports

const TotalEmailVerified = ({
  selectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);
  ChartJS.register(ArcElement, Tooltip, Legend);

  // state variables
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [totalEmailVerified, setTotalEmailVerified] = useState("")
  const [totalEmailNotVerified, setTotalEmailNotVerified] = useState("")

  const graphData = {
    labels: ['Verified', 'Not Verified'],
    datasets: [
      {
        label: false,
        data: [parseInt(totalEmailVerified), parseInt(totalEmailNotVerified)],
        backgroundColor: [
          '#FD6F0F',
          '#23B7E5',
        ],
        borderColor: [
          'r#FD6F0F',
          '#23B7E5',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true, // Show the legend
        position: 'bottom', // Position the legend at the bottom
        align: 'center', // Center the legend items
        labels: {
          boxWidth: 20, // Adjust box width for better spacing
          padding: 20,  // Add padding between legend items
        },
        
      },
      datalabels: {
        display: false, // Disable the labels on the wedges
      }
    },
  };
  
  // use effects
  useEffect(() => {
    fetchTotalEmailVerifiedNumbers()
  }, [])

  // api functions
  const fetchTotalEmailVerifiedNumbers = async () => {

    setIsApiRunning(true)
    
    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_EMAIL_VERIFIED)

      setTotalEmailVerified(response?.data?.data?.email_verified)
      setTotalEmailNotVerified(response?.data?.data?.email_not_verified)

      setIsApiRunning(false)

    } catch (error) {
      setIsApiRunning(false)
      handleExpiredTokenError(navigate, error?.response);
      console.log("error fetching total num users", error?.response)
    }

  }

  // helper functions
 

  return (
    <div className="total-email-verified">

      <div className='total-email-title-container'>
        Email Verifications
      </div>

      {
        isApiRunning
        ? (
          <RebetLoader 
          height={'50px'}
          width={'50px'}
          />
        ) : (
          <div className='total-email-graph-container'>
            <Pie data={graphData} options={options} />
          </div>
        )
      }

    </div>
  );
};

export default TotalEmailVerified;