// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesTransactionRow.css'
import { NAVIGATION_ROUTES } from '../../../../../utils/NavigationUtils';

// component imports

// asset imports
import EditIcon from '../../../../../assets/icons/ic_edit_green.png'
import CashIcon from '../../../../../assets/icons/ic_cash.png'
import CoinIcon from '../../../../../assets/icons/ic_coin.png'

const REASON = {
  BET_WON: "Bet Won", 
  BET_LOST: "Bet Lost", 
  BET_CREATE: "Bet Create", 
  BONUS: "Bonus"
}

const TransactionRow = ({
  is_header_row, 
  transaction_data
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects
  useEffect(() => {
  }, [transaction_data])

  // api functions

  // helper functions
  const formatTimestamp = (timestampStr) => {
    const timestamp = parseFloat(timestampStr.split('.')[0]);
  
    const date = new Date(timestamp * 1000);
  
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // Special case for teens
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }
  
    // Formatting the date
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);
  
    // Return the formatted string
    return `${formattedHours}:${formattedMinutes} ${ampm}, ${month} ${day}${ordinalSuffix}`;
  }


  return (
    <div className={`table-row ${is_header_row ? 'table-header-row' : ''}`}>

    <div className='table-cell transaction-cell'>
        <p className={ is_header_row ? 'transaction-cell-label' : 'transaction-id-cell-label'}>
          {
            is_header_row ?
            "Timestamp" :
            formatTimestamp(transaction_data?.timestamp)
          }
        </p>
      </div>

      <div className='table-cell transaction-cell'>
        <p className={ is_header_row ? 'transaction-cell-label' : 'transaction-id-cell-label'}>
          {
            is_header_row ?
            "ID" :
            transaction_data?.transaction_id
          }
        </p>
      </div>

      <div className='table-cell transaction-cell'>
      {
        is_header_row 
        ? (
          
            <p className='transaction-cell-label'>
              Money Type
            </p>
         
        )
        : (
          <p className='transaction-cell-label'>
          {transaction_data?.wallet_type}
          </p>
        )
      }
      </div>
     

      <div className='table-cell transaction-cell'>
        <p className='transaction-cell-label'>
          {
            is_header_row ?
            "Amount" :
            transaction_data?.amount
          }
        </p>
      </div>

      <div className='table-cell transaction-cell'>
        <p className='transaction-cell-label'>
          {
            is_header_row ?
            "Type" :
            transaction_data?.transaction_type
          }
        </p>
      </div>

      <div className='table-cell transaction-cell'>
        <div className={
          is_header_row
          ? ""
          : (
            transaction_data?.title === REASON?.BET_WON || transaction_data?.title === REASON?.BONUS
            ? "transaction-reason-text-background-good"
            : transaction_data?.title === REASON?.BET_CREATE
            ? "transaction-reason-text-background-negative"
            : "transaction-reason-text-background-unsettled" 
          )
        }>
           <div className={
              is_header_row
              ? "transaction-cell-label"
              : (
                transaction_data?.title === REASON?.BET_WON || transaction_data?.title === REASON?.BONUS
                ? "transaction-reason-text-good"
                : transaction_data?.title === REASON?.BET_CREATE
                ? "transaction-reason-text-negative"
                : "transaction-reason-text-unsettled" 
              )
            }>
            {
              is_header_row ?
              "Reason" :
              transaction_data?.title
            }
          </div>
        </div>
      </div>


      <div className='table-cell transaction-cell'>
        <p className='transaction-cell-label'>
          {
            is_header_row ?
            "Balance" :
            transaction_data?.total_balance
          }
        </p>
      </div>

    </div>
  );
};

export default TransactionRow;

