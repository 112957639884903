export const PLAID_STATUSES = {
    VERIFIED: "verified", 
    FAILED: "failed",
    ACTIVE: "active",
    PENDING_REVIEW: "pending_review",
    NOT_VERIFIED: "not_verified"
  }

export const determineSuspensionStatus = (user_data) => {
    if ((user_data?.suspended && user_data?.suspended === "0") || !user_data?.suspended) {
        return false
    } 
    else {
        return true
    }
}