import React, { useEffect, useState } from 'react';

import './stylesFinancials.css'
import FinancialsStats from './FinancialsStats/FinancialsStats';
import FinancialsGraph from './FinancialsGraph/FInancialsGraph';
import FinancialsTable from './FinancialsTable/FinancialsTable';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../api/ApiRoutes';
import axios from 'axios';
import moneyFormatter from '../../../utils/MoneyFormatter';

function Financials({ referralCodes }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const [isApiRunning, setIsApiRunning] = useState(false);
  const [financialsStats, setFinancialsStats] = useState({});

  const getFinancialsStats = async () => {
    setIsApiRunning(true);

    try {
      const requests = [
        PrivateAdminPanelInstance?.post(API_ROUTES?.TOTAL_YEAR_COMMISSION_PAIDOUT),
        PrivateAdminPanelInstance?.post(API_ROUTES?.ADMINS_TOTAL_COMMISSION_MONTHLY),
        PrivateAdminPanelInstance?.post(API_ROUTES?.GET_AVG_CAC),
        PrivateAdminPanelInstance?.post(API_ROUTES?.ADMINS_TOTAL_REVENUE),
      ];

      const responses = await Promise.allSettled(requests);

      const [totalCommissionPaidOutResponse, monthlyCommissionOwedResponse, avgCacResponse, totalRevenueResponse] = responses;
      
      const totalCommissionPaidOut = totalCommissionPaidOutResponse.status === 'fulfilled'
        ? moneyFormatter(totalCommissionPaidOutResponse.value.data.data.total_year_commission_paidout)
        : '0.0'

      const monthlyCommissionOwed = monthlyCommissionOwedResponse.status === 'fulfilled'
        ? moneyFormatter(monthlyCommissionOwedResponse.value.data.data.total_month_commission)
        : '0.0'

      const avgCac = avgCacResponse.status === 'fulfilled'
        ? moneyFormatter(avgCacResponse.value.data.data.avg_cac)
        : '0.0'
      
        const totalRevenue = totalRevenueResponse.status === 'fulfilled'
        ? moneyFormatter(totalRevenueResponse.value.data.data.total_revenue)
        : '0.0'

      setFinancialsStats({
        totalCommissionPaidOut,
        monthlyCommissionOwed,
        avgCac,
        totalRevenue,
      });

      setIsApiRunning(false);

    } catch (error) {
      setIsApiRunning(false);
      console.log("error financials stats", error);
    }

  };


  useEffect(() => {
    getFinancialsStats();
  }, []);

  return (
    <div className='financials-container'>
      <div className='top-content'>
        <FinancialsStats financialsStats={financialsStats} isApiRunning={isApiRunning}/>
        <FinancialsGraph referralCodes={referralCodes} />
      </div>
      <div className='bottom-content'>
        <FinancialsTable referralCodes={referralCodes} />
      </div>
    </div>
  );
}

export default Financials;