import React, { useEffect, useState } from 'react';
import DropDown from '../../CommonComponents/DropDown/DropDown';

import './stylesIndividualStats.css'
import IndividualStatsTable from './IndividualStatsTable/IndividualStatsTable';
import createPrivateAdminPanelInstance from '../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../api/ApiRoutes';
import RebetLoader from '../../CommonComponents/RebetLoader/RebetLoader';
import axios from 'axios';
import moneyFormatter from '../../../utils/MoneyFormatter';

function IndividualStats({ referralCodes }) {
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  const [selectedReferralCode, setSelectedReferralCode] = useState({ referralCode: '', data: [] });
  const [individualStats, setIndividualStats] = useState({
    total_referrals: 0,
    monthly_referrals: 0,
    total_ftds: 0,
    monthly_ftds: 0,
    total_commission_paid: 0,
    total_commission_owed: 0,
    total_ltv: 0,
  });
  const [isApiRunning, setIsApiRunning] = useState(false);

  const getReferralCode = async (referralCode) => {
    const params = {
      referral_codes: [referralCode]
    }

    setIsApiRunning(true)

    try {
      const requests = [
        // PrivateAdminPanelInstance?.post(API_ROUTES?.GET_FTD_LIST, params),
        PrivateAdminPanelInstance?.post(API_ROUTES?.GET_REFERRAL_STATS, params),
      ];

      const responses = await Promise.allSettled(requests);

      const [individualStatsResponse] = responses;


      /** 
       * 
       * I'M LEAVING THE COMMENTS BEACAUSE WE ARE GOING TO WORK ON THIS LATER
       * 
      */

      // const [ftdListResponse, individualStatsResponse] = responses;

      // if (ftdListResponse.status === 'fulfilled') {
      //   const ftdList = ftdListResponse.value.data.data;
      //   setSelectedReferralCode({ referralCode, data: ftdList });
      // } else {
      //   console.error("Erro getting FTD list:", ftdListResponse.reason);
      // }

      if (individualStatsResponse.status === 'fulfilled') {
        const individualStats = individualStatsResponse.value.data.data;
        setIndividualStats(individualStats);
        setSelectedReferralCode({ referralCode, data: [] });
      } else {
        console.error("Erro getting individual stats:", individualStatsResponse.reason);
      }

      setIsApiRunning(false);

    } catch (error) {
      setIsApiRunning(false);
      console.error("error getting referral codes", error);
    }

  }

  useEffect(() => {
    getReferralCode('PISTONS')
  }, [])

  return (
    <div className='individual-stats-container'>
      {
        isApiRunning ? (
          <RebetLoader
            height={'70px'}
            width={'70p'}
          />
        ) :
          <>
            <div className='individual-stats-header'>
              <DropDown
                drop_down_options={referralCodes}
                selected_value={selectedReferralCode.referralCode}
                set_selected_value={(e) => getReferralCode(e)}
              />
            </div>
            <div className='individual-stats-body'>
              <div className='individual-stats-percentage'>
                <div className='individual-stats-top-left'>
                  <div className="individual-stat">
                    <h2 className="stat-title">Total Sign Ups</h2>
                    <div className="stat-value">{individualStats.total_referrals}</div>
                  </div>
                  <div className="individual-stat">
                    <h2 className="stat-title">Monthly Sign Ups</h2>
                    <div className="stat-value">{individualStats.monthly_referrals}</div>
                  </div>
                </div>
                <div className='individual-stats-top-right'>
                  <div className="individual-stat">
                    <h2 className="stat-title">Total FTDs</h2>
                    <div className="stat-value">{individualStats.total_ftds}</div>
                  </div>
                  <div className="individual-stat">
                    <h2 className="stat-title">Monthly FTDs</h2>
                    <div className="stat-value">{individualStats.monthly_ftds}</div>
                  </div>
                </div>
              </div>
              <div className='individual-stats-commision'>
                <div className='individual-stat'>
                  <h2 className="stat-title">Total Commission Paid Out</h2>
                  <div className="stat-value">$ {moneyFormatter(individualStats.total_commission_paid)}</div>
                </div>
                <div className='individual-stat'>
                  <h2 className="stat-title">Monthly Commission Owed</h2>
                  <div className="stat-value">$ {moneyFormatter(individualStats.total_commission_owed)}</div>
                </div>
                <div className='individual-stat'>
                  <h2 className="stat-title">Monthly LTV Per User</h2>
                  <div className="stat-value">$ {moneyFormatter(individualStats.total_ltv)}</div>
                </div>
              </div>
              <IndividualStatsTable data={selectedReferralCode} />
            </div>
          </>
      }
    </div>
  );
}

export default IndividualStats;