// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { X } from 'react-feather';

// internal rebet imports
import './stylesEditUserDataPopup.css'
import createPrivateAdminPanelInstance from '../../../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../../../utils/ErrorUtils';

// component imports
import RebetLoader from '../../../../../CommonComponents/RebetLoader/RebetLoader';
import { API_ROUTES } from '../../../../../../api/ApiRoutes';

// asset imports


const EditUserDataPopup = ({
  isVisible, 
  onClosePressed, 
  userData,
  refreshUserData
}) => {

  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken)
  const navigate = useNavigate()

  // state variables
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [bio, setBio] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [lastApiCallError, setLastApiCallError] = useState(false)
  const [lastApiErrorMessage, setLastApiErrorMessage] = useState("")
  const [lastApiCallSuccess, setLastApiCallSuccess] = useState(false)
  const [lastApiCallSuccessMessage, setLastApiCallSuccessMessage] = useState("")
  
  // use effects'
  useEffect(() => {
    setFirstName(userData?.first_name)
    setLastName(userData?.last_name)
    setBio(userData?.bio)
    setPhoneNumber(userData?.phone_number)
  }, [userData])


  // api functions
  const editUserData = async () => {
    setIsApiRunning(true)

    setLastApiCallError(false)
    setLastApiErrorMessage("")
    setLastApiCallSuccess(false)
    setLastApiCallSuccessMessage("")

    const params = {
      user_id: userData?.user_id, 
      first_name: firstName, 
      last_name: lastName, 
      bio: bio, 
      phone_number: phoneNumber
    }

    console.log("PARAMS", params)

    try {

      await PrivateAdminPanelInstance?.post(API_ROUTES?.EDIT_USER_PROFILE, params)

      refreshUserData()

      setLastApiCallSuccess(true)
      setLastApiCallSuccessMessage(`${userData?.username} edited successfully!`)
      setIsApiRunning(false)

    } catch (error) {
      console.log("Error editing the user data", error?.response)
      setIsApiRunning(false)
      setLastApiCallError(true)
      setLastApiErrorMessage(error?.response?.data?.message)
      handleExpiredTokenError(navigate, error?.response)
    }


  }

  // helper functions
  const handleClosePressed = () => {
    onClosePressed()
  }
  
  const handleChangeFirstName = (event) => {
    setFirstName(event?.target?.value)
  }

  const handleChangeLastName = (event) => {
    setLastName(event?.target?.value)
  }

  const handleChangeBio = (event) => {
    setBio(event?.target?.value)
  }

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event?.target?.value)
  }

  const handleEditUserDataPressed = () => {
    editUserData()
  }

 
  if(!isVisible) return null;
  return (
    <div className="suspend-user-popup">

      <div className='suspend-user-panel'>

      <div className='suspend-user-header'>

        <div className='suspend-user-header-label'>
          Edit {userData?.username}
        </div>

        <button 
        className='close-button'
        onClick={handleClosePressed}
        >
          <X 
          className='close-icon'
          />
        </button>
      </div>

      <input
          type="text"
          placeholder="First name..."
          className="edit-user-input"
          value={firstName}
          onChange={handleChangeFirstName}
        /> 

      <input
          type="text"
          placeholder="Last name..."
          className="edit-user-input"
          value={lastName}
          onChange={handleChangeLastName}
        />   

        <input
          type="text"
          placeholder="Bio..."
          className="edit-user-input"
          value={bio}
          onChange={handleChangeBio}
        />  

        <input
          type="text"
          placeholder="Phone number (include + code)..."
          className="edit-user-input"
          value={phoneNumber}
          onChange={handleChangePhoneNumber}
        />   

      <div className='suspend-button-container'>
          <button 
            className={
              'submit-button'
            }
            onClick={handleEditUserDataPressed}
          >
            {
              isApiRunning
              ? (
                <RebetLoader 
                height={'50px'}
                width={'50px'}
                />
              )
              : "Edit User"
            }
          </button>
      </div>

      {
        lastApiCallError 
        ? (
          <div className='error-message'>
            {lastApiErrorMessage}
          </div>
        ) 
        : null
      }

      {
        lastApiCallSuccess 
        ? (
          <div className='success-message'>
            {lastApiCallSuccessMessage}
          </div>
        ) 
        : null
      }

      </div>
      
    </div>
  );
};

export default EditUserDataPopup;

