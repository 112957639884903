// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesGetEmailStatusByDay.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import { DATE_RANGE, TIME_FRAMES } from '../../../../utils/Constants';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader'
import { formatDatesArray } from '../../../../utils/DateFormatter';
import getLastXDays from '../../../../utils/GetLastXDays';
import DropDown from '../../../CommonComponents/DropDown/DropDown';

// asset imports

const GetEmailStatusByDay = () => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  // state variables
  const [apiIsRunning, setApiIsRunning] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('Last 7 days')

  // use effects
  useEffect(() => {
    const getEmailStatusByDay = async () => {

      setApiIsRunning(true)

      try {
        const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_EMAIL_STATUS_BY_DAY)

        const data = response?.data?.data

        setGraphData(data)
        setApiIsRunning(false)

      } catch (error) {
        setApiIsRunning(false)
        console.log("error fetching Email Status By Day data", error)
      }
    }

    getEmailStatusByDay()
  }, [])

  // api functions


  // helper functions 

  const lastXDays = getLastXDays(selectedTimeFrame, graphData);

  const labels = formatDatesArray(Object.keys(lastXDays).reverse(), TIME_FRAMES.DAY);
  const verified = Object.values(lastXDays).map(value => value.verified).reverse();
  const pending = Object.values(lastXDays).map(value => value.pending).reverse();

  const data = {
    labels: labels, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'Verified',
        data: verified, // Y-axis values from the data
        backgroundColor: '#26BF94',
        borderRadius: 10
      },
      {
        label: 'Pending',
        data: pending, // Y-axis values from the data
        backgroundColor: '#F5B849',
        borderRadius: 10
      },
    ],
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        },
        afterDataLimits: (axis) => {
          axis.max = axis.max * 1.1; // Increase the max value by 10% to make space for labels
        }
      },
    },
  };



  return (
    <div className='email-status-by-day-container'>
      <div className="email-status-by-day-graph">
        <div className="email-status-by-day-graph-header-row">
          <div className='left-half-graph-header'>
            <div className="email-status-by-day-graph-header-label">
              Email Status By Day - Verified
            </div>
            <div className='right-half-graph-header'>
              <DropDown
                drop_down_options={DATE_RANGE}
                selected_value={selectedTimeFrame}
                set_selected_value={(value) => setSelectedTimeFrame(value)}
              />
            </div>
          </div>
        </div>
        <div className="email-status-by-day-graph-container">
          {apiIsRunning ? (
            <RebetLoader height={'150px'} width={'150px'} />
          ) : graphData ? (
            <Bar data={data} options={options} />
          ) : (
            <div>No data available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetEmailStatusByDay;
