// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// internal rebet imports
import './stylesSportsbookHeader.css'
import { SPORTSBOOK_HEADER_TABS } from '../../../utils/Constants';

// component imports

// asset imports


const SportsbookHeader = ({
  selectedTab,
  setSelectedTab
}) => {
  // non-state variables
  const navigate = useNavigate();

  // state variables
  
  // use effects'

  // api functions

  // helper functions
  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab)
  }
 

  return (
    <div className='panel-header'>

      <button 
        className={selectedTab === SPORTSBOOK_HEADER_TABS?.LEAGUES ? 'header-tab-selected': 'header-tab'}
        onClick={() => handleSetSelectedTab(SPORTSBOOK_HEADER_TABS?.LEAGUES)}
      >
        <p className={selectedTab === SPORTSBOOK_HEADER_TABS?.LEAGUES ? 'tab-text-selected': 'tab-text'}>
          Leagues
        </p>
      </button>

    </div>
  );
};

export default SportsbookHeader;

