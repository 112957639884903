// third party imports
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// internal rebet imports
import './stylesTransactionTable.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader';
import TransactionRow from './TransactionRow/TransactionRow';

// asset imports
import SearchIcon from '../../../../assets/icons/ic_search.png'

const sample_user = {
  user_id: "123123",
  username: "conman", 
  email: "clseeley1@gmail.com",
  suspended: "false", 
  kyc_status: "passed"
}

const TransactionTable = ({
  userData
}) => {
  // non-state variables
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);
  const navigate = useNavigate()

  // state variables
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState()
  const [isApiRunning, setIsApiRunning] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [transactionReason, setTransactionReason] = useState("")
  
  // use effects
  useEffect(() => {
    getUsersTransactions()
  }, [userData])

  // api functions
  const getUsersTransactions = async () => {
    setIsApiRunning(true)

    const params = {
      user_id: userData?.user_id, 
      last_evaluated_key: lastEvaluatedKey
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TRANSACTION_HISTORY, params)

      const all_transactions = [...transactions, ...response?.data?.data?.items]
      const all_transactions_sorted = all_transactions?.sort((a, b) => {
        return parseFloat(b.timestamp) - parseFloat(a.timestamp);
      });
      const all_unique_transactions_sorted = Array.from(
        new Map(all_transactions_sorted.map(transaction => [transaction.transaction_id, transaction])).values()
      );

      setTransactions(all_unique_transactions_sorted)
      setLastEvaluatedKey(response?.data?.data?.last_evaluated_key)

      setIsApiRunning(false)

    } catch (error) {
      console.log("error fetching user transaction history", error?.response)
      setIsApiRunning(false)
      handleExpiredTokenError(navigate, error?.response)
    }

  }

  // helper functions


  const handleMoreTransactionsPressed = () => {
    getUsersTransactions()
  }


  return (
    <div className="user-table">

      <div className='head-controls'>

        <div className='header-text-container'>
            <p className='header-text'>
              {userData?.username}'s Transactions
            </p>
        </div>

         {/*<input
            type="text"
            placeholder="Search transactions..."
            className="searchInput"
            value={searchValue}
            onChange={handleSearchValueChange}
          />

        <div className='drop-down-container'>
          <DropDown 
          drop_down_options={SEARCH_OPTIONS}
          selected_value={searchBy}
          set_selected_value={handleChangeSearchBy}
          />
        </div>

        <button
          className='search-button'
          onClick={handleSearchPressed}
        >
          <img 
            className='search-icon'
            src={SearchIcon}
          />
  </button> */}

      </div>

      <div className='table-container'>

        <TransactionRow 
        is_header_row={true}
        user_data={{}}
        />

      {
        isApiRunning
        ? (
          <RebetLoader 
          height={'100px'}
          width={'100px'}
          />
        ) : (
          <div className='data-rows-container'>
          {transactions && transactions.map((transaction, index) => (
                  <TransactionRow
                    key={transaction?.transaction_id}
                    is_header_row={false}
                    transaction_data={transaction}
                  />
                ))}
          </div>
        )
      }
      </div>
      
      {!isApiRunning &&
        <div className='table-controls-container'>
              <p className='next-text' onClick={handleMoreTransactionsPressed}>
                More Transactions
              </p>
        </div>
      }

    </div>
  );
};

export default TransactionTable;

