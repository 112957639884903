import { API_ROUTES } from "../ApiRoutes"

export const fetchSpecificUsersData = async (
    ApiInstance, 
    user_id
) => {

    const params = {
        search_by: "user_id",
        search_str: user_id
    }

    try {

        const response = await ApiInstance?.post(API_ROUTES?.SEARCH_USER, params)

        console.log("response", response)

        return response?.data?.data

    } catch (error) {
        console.log("error fetching user", error?.response)
    }

}