// third party imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// internal rebet imports
import './stylesPrizeRedemptions.css'
import createPrivateAdminPanelInstance from '../../../../api/PrivateAdminPanelInstance';
import { handleExpiredTokenError } from '../../../../utils/ErrorUtils';
import { API_ROUTES } from '../../../../api/ApiRoutes';
import { TIME_FRAMES } from '../../../../utils/Constants';

// component imports
import RebetLoader from '../../../CommonComponents/RebetLoader/RebetLoader'
import DropDown from '../../../CommonComponents/DropDown/DropDown';

// asset imports

const PrizeRedemptions = () => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(token?.IdToken);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  

  // state variables
  const [apiIsRunning, setApiIsRunning] = useState(false);
  const [graphData, setGraphData] = useState({}); 
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(TIME_FRAMES?.MONTH)

  // use effects
  useEffect(() => {
    getPurchases(selectedTimeFrame)
  }, [selectedTimeFrame])

  // api functions
  const getPurchases = async ( time_period ) => {

    setApiIsRunning(true)

    const params = {
      group_by: time_period
    }

    try {

      const response = await PrivateAdminPanelInstance?.post(API_ROUTES?.GET_TOTAL_PRIZE_REDEMPTIONS, params)

      setGraphData(response?.data?.data)
      setApiIsRunning(false)

    } catch (error) {
      setApiIsRunning(false)
      console.log("error fetching purchase data", error?.response)
    }

  }

  // helper functions 
  const handleSetTimeFrame = (value) => {
    setSelectedTimeFrame(value)
  }

  const labels = Object.keys(graphData);
  const dataValues = Object.values(graphData).map(value => parseFloat(value?.amount));

  const data = {
    labels: labels, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: 'PrizeRedemptions',
        data: dataValues, // Y-axis values from the data
        backgroundColor:  '#E6533C',
        borderRadius: 10
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: '#FFF',  // Set the color of the y-axis labels
        },
        afterDataLimits: (axis) => {
          axis.max = axis.max * 1.1; // Increase the max value by 10% to make space for labels
        }
      },
    },
  };



  return (
    <div className="wagers-by-day-graph">
      <div className="wagers-by-day-graph-header-row">
        <div className='left-half-graph-header'>
            <div className="wagers-by-day-graph-header-label">
              Total Prize Redemptions
            </div>
          </div>
          <div className='right-half-graph-header'>
            <DropDown 
              drop_down_options={TIME_FRAMES}
              selected_value={selectedTimeFrame}
              set_selected_value={handleSetTimeFrame}
              />
          </div>
      </div>
      <div className="wagers-graph-container">
        {apiIsRunning ? (
          <RebetLoader height={'150px'} width={'150px'} />
        ) : graphData ? (
          <Bar data={data} options={options} />
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default PrizeRedemptions;
