import React from 'react';
import Lottie from 'lottie-react'
import LoaderJSON from '../../../assets/animations/loading_animation_data.json'

const RebetLoader = ({height, width}) => {
  return (
    <div>
      <Lottie 
      animationData={LoaderJSON} 
      loop={true} 
      style={{
        height: height, 
        width: width
      }}
      />
    </div>
  );
};

export default RebetLoader;